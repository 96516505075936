import { PlatformActionTypes } from 'src/actions/platformActions';
import { getShortDataFormat, isPlatformPIN, isUSAPlatform } from '../../utils';

export interface PlatformState {
  isPlatformPIN: boolean;
  isUSAPlatform: boolean;
  shortDateFormat: string;
}

const initialState: PlatformState = {
  isPlatformPIN,
  isUSAPlatform,
  shortDateFormat: getShortDataFormat(),
};

export const platformReducer = (
  state = initialState,
  action: PlatformActionTypes
): PlatformState => {
  switch (action.type) {
    default:
      return state;
  }
};
