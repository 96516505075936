import { Button, Checkbox, Col, notification, Row, Tooltip } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { config } from 'config/config';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import * as api from 'services/api';
import apiPaths from 'services/apiPaths';
import {
  IChallengeExternalRedeemPointsPoints,
  IChallengeResourceRender,
  IExpirationPointsInfo,
  IExternalRedeemPoints,
} from 'src/shared/models';
import { customFormatMessage } from '../../../../utils';
import ModalsJourney from '../../modal_journey/modal_journey';
import { IModalJourneyIterator } from '../../modal_journey/modal_journey.interfaces';
import { JourneyModalIterator } from '../../modal_journey/modal_journey_iterator';
import {
  ExternalRedeemPointsTypeEnum,
  ExternalRedeemPointsTypeHandler,
} from './ExternalRedeemPoints.enum';
import { SetUserDataUpdate } from 'src/actions/authActions';
import { Dispatch } from 'redux';
import { IRootReducers } from 'src/reducers';

const { ASSET_PREFIX } = config.APP;
const { EXTERNAL_REDEEM_DONATION, EXTERNAL_REDEEM_ISD } =
  ExternalRedeemPointsTypeEnum;

interface OwnProps extends IChallengeResourceRender {
  config: IExternalRedeemPoints;
  setAllCardsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  shouldStartModalJourney: boolean;
  selectedCard: IChallengeExternalRedeemPointsPoints;
  updateUserExpirationPoints: (
    expirationPoints: IExpirationPointsInfo
  ) => (
    dispatch: Dispatch<SetUserDataUpdate>,
    getState: () => IRootReducers
  ) => void;
}

type Props = OwnProps;

export const ExternalRedeemPointsModal: FC<Props> = ({
  config,
  shouldStartModalJourney,
  selectedCard,
  setAllCardsDisabled,
  updateChallenge,
  updateUserPoints,
  updateUserExpirationPoints,
}): JSX.Element => {
  const {
    tcModalButtonLabel,
    tcModalDescription,
    tcModalFile,
    completeModalTitle,
    completeModalDescription,
    completeModalButtonLabel,
    completeModalWarning,
    type,
  } = config;
  const { formatMessage } = useIntl();
  const parsedTCUrl: { url: string; type: string } = JSON.parse(tcModalFile);
  const [areTermsAccepted, setTermsAccepted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isOnCourse, setIsOnCourse] = useState<boolean>(false);
  const [showCopyTooltip, setShowCopyTooltip] = useState<boolean>(false);

  const couponCodeRef = useRef(null);

  const journeyCollection = new JourneyModalIterator({
    closeJourney: () => {
      setIsOnCourse(false);
      setTermsAccepted(false);
      setLoading(false);
    },
  });

  useEffect(() => {
    if (shouldStartModalJourney) {
      setIsOnCourse(true);
    }
  }, [shouldStartModalJourney]);

  const buildIteratorDonation = (collection: IModalJourneyIterator): void => {
    collection.addStep({
      key: 'step-donation--accept-terms-&-conditions',
      content: (
        <div className="external-redeem-points-modal external-redeem-points-modal__donation">
          <DocumentIcon />
          <Row className="title">{selectedCard?.acceptanceMessage}</Row>
          <Row className="terms-conditions terms-conditions__donation">
            <Checkbox
              checked={areTermsAccepted}
              onChange={(event: CheckboxChangeEvent) => {
                setTermsAccepted(event.target.checked);
              }}
            />
            <Col className="label">{termsComponent()}</Col>
          </Row>
          <Button
            className="button button__donation btn-primary btn-primary--black"
            disabled={!areTermsAccepted}
            onClick={async () => {
              try {
                collection.nextStep();
                await submitExternalRedeemPoints();
                setAllCardsDisabled(true);
              } catch (error) {
                console.error('Could not complete the external redeem', error);
              }
            }}
          >
            {tcModalButtonLabel}
          </Button>
        </div>
      ),
    });

    collection.addStep({
      key: 'step-donation--success-message',
      content: (
        <div className="external-redeem-points-modal external-redeem-points-modal_donation">
          <GreenCheckIcon />
          <Row className="title">{completeModalTitle}</Row>
          <Row className="subtitle">{completeModalDescription}</Row>
          <CompleteModalButton
            customClass={
              'button button__complete-modal btn-primary btn-primary--black'
            }
            buttonLabel={completeModalButtonLabel}
            onClick={async () => {
              collection.nextStep();
            }}
          />
        </div>
      ),
    });
  };

  const buildIteratorISD = (collection: IModalJourneyIterator): void => {
    collection.addStep({
      key: 'step-isd--accept-terms-&-conditions',
      content: (
        <div className="external-redeem-points-modal external-redeem-points-modal__isd">
          <DocumentIcon />
          <Row className="title">{selectedCard?.acceptanceMessage}</Row>
          <Row className="subtitle">{tcModalDescription}</Row>
          <Row className="terms-conditions terms-conditions__isd">
            <Checkbox
              checked={areTermsAccepted}
              onChange={(event: CheckboxChangeEvent) => {
                setTermsAccepted(event.target.checked);
              }}
            />

            <Col className="label">{termsComponent()}</Col>
          </Row>
          <Button
            className="button button__isd btn-primary btn-primary--black"
            disabled={!areTermsAccepted}
            onClick={async () => {
              try {
                collection.nextStep();
                await submitExternalRedeemPoints();
                setAllCardsDisabled(true);
              } catch (error) {
                console.error(
                  'Could not complete the external redeem ISD',
                  error
                );
              }
            }}
          >
            {tcModalButtonLabel}
          </Button>
        </div>
      ),
    });

    collection.addStep({
      key: 'step-isd--success-message',
      content: (
        <div className="external-redeem-points-modal external-redeem-points-modal__isd">
          <GreenCheckIcon />
          <Row className="title">{completeModalTitle}</Row>
          <Row className="subtitle">{completeModalDescription}</Row>
          <Row className="coupon-copy">
            <div className="copy-tooltip">
              <Tooltip
                visible={showCopyTooltip}
                title={formatMessage({
                  id: 'component.external-redeem-points.copy.message',
                })}
              />
            </div>
            <div className="coupon" ref={couponCodeRef}>
              {selectedCard?.couponCode}
            </div>

            <div className="copy-wrapper">
              <img
                loading="lazy"
                className="icon-copy"
                src={`${ASSET_PREFIX}/copy.svg`}
                onClick={copyToClipboard}
              />
              <div className="text-copy" onClick={copyToClipboard}>
                {formatMessage({ id: 'component.external-redeem-points.copy' })}
              </div>
            </div>
          </Row>
          <div className="text-warning">{completeModalWarning}</div>
          <CompleteModalButton
            customClass={
              'button button__complete-modal btn-primary btn-primary--black'
            }
            buttonLabel={completeModalButtonLabel}
            onClick={async () => {
              setShowCopyTooltip(false);
              collection.nextStep();
            }}
          />
        </div>
      ),
    });
  };

  const copyToClipboard = async () => {
    const textField = couponCodeRef.current;
    if (!textField) return;

    if (!showCopyTooltip) {
      setShowCopyTooltip(true);

      setTimeout(() => {
        setShowCopyTooltip(false);
      }, 8000);
    }
    await navigator.clipboard.writeText(selectedCard?.couponCode);
  };

  const submitExternalRedeemPoints = async () => {
    setLoading(true);
    try {
      const response = await api.putDataCallById({
        dataPath: apiPaths.CHALLENGES.COMPLETE_EXTERNAL_REDEEM,
        id: selectedCard?.id,
      });

      const { score, expirationPoints, challenge } = response.data;
      updateUserPoints(score);
      updateUserExpirationPoints(expirationPoints);
      updateChallenge(challenge);
    } catch (error) {
      notification.error({
        message: formatMessage({
          id: 'external-redeem-points.completion.error',
        }),
      });
    } finally {
      setLoading(false);
    }
  };

  const termsComponent = (): JSX.Element => {
    return (
      <div>
        {customFormatMessage(
          'component.external-redeem-points.tc-modal.tc.label',
          {
            terms: (chunks: React.ReactNode) => (
              <a href={parsedTCUrl.url} target="_blank" className="underlined">
                {chunks}
              </a>
            ),
          }
        )}
      </div>
    );
  };

  const DocumentIcon = (): JSX.Element => {
    return (
      <Row>
        <img
          loading="lazy"
          className="icon-document"
          src={`${ASSET_PREFIX}/document.svg`}
        />
      </Row>
    );
  };

  const GreenCheckIcon = (): JSX.Element => {
    return (
      <Row>
        <img
          loading="lazy"
          className="icon-document"
          src={`${ASSET_PREFIX}/green-check-white-bg.svg`}
        />
      </Row>
    );
  };

  interface CompleteModalButtonProps {
    customClass: string;
    buttonLabel: string;
    onClick: () => Promise<void>;
  }

  const CompleteModalButton = ({
    customClass,
    buttonLabel,
    onClick,
  }: CompleteModalButtonProps): JSX.Element => {
    return (
      <Button
        className={customClass}
        block
        onClick={async () => {
          await onClick();
        }}
      >
        {buttonLabel}
      </Button>
    );
  };

  const iteratorBuilder: ExternalRedeemPointsTypeHandler = {
    [EXTERNAL_REDEEM_DONATION]: buildIteratorDonation,
    [EXTERNAL_REDEEM_ISD]: buildIteratorISD,
  };

  if (type) iteratorBuilder[type](journeyCollection);

  return (
    <ModalsJourney
      isOnCourse={isOnCourse}
      isLoading={loading}
      journeyCollection={journeyCollection}
    />
  );
};

export default connect()(ExternalRedeemPointsModal);
