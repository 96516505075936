import { Button, Layout, Row } from 'antd';
import routePaths from 'config/routes';
import { NextPage } from 'next';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { getRedeemPointMagentoCart, showBagMenu } from 'src/actions/appActions';
import Link from 'src/components/Link';
import BasicMenu from 'src/components/layout/BasicMenu';
import CommercialDisclaimer from 'src/components/layout/CommercialDisclaimer';
import DrawerMenu from 'src/components/menu/DrawerMenu';
import { getMenu } from 'src/components/menu/Menu.utils';
import { IRootReducers } from 'src/reducers';
import { IMenu, IRedeemPointsProduct } from 'src/shared/models';
import { UserStatusEnum } from '../../shared/enums';
import PlatformSelector from '../platformSelector/PlatformSelector';
import { getToastProps } from '../toast/toast.utils';
import Disclaimer from './Disclaimer';
import getConfig from 'next/config';
import { useShowCompleteProfile } from 'src/hooks/useShowCompleteProfile';
import {
  HeaderNavItemProps,
  styled,
  YuHeader,
  YuIcon,
  YuLogoSecondary,
} from '@isdin/yuma-react-web-pin';
import {
  customFormatMessage,
  redeemPointStatus,
  requestOrderStatus,
} from '../../../utils';
import { shouldGoToDelegatePage } from 'src/shared/enums/platform.enum';
import ConditionalRenderer from '../ConditionalRenderer';
import redirectTo from 'services/redirectTo';
import { addToast } from 'src/actions/toastActions';

const MIN_SCROLL_POSITION = 120;

const YumaHeaderStyled = styled(YuHeader)`
  .rowElement {
    padding: 0;
  }
`;

interface ILayoutHeader {
  showDrawerMenu: boolean;
  authenticated: boolean;
  componentLogo: JSX.Element;
  componentButtons: JSX.Element;
  componentCustomMenu: JSX.Element;
  toggleHeaderFixed: () => void;
  menu: IMenu[];
}

const LayoutHeader = ({
  showDrawerMenu,
  authenticated,
  componentLogo,
  componentButtons,
  componentCustomMenu,
  toggleHeaderFixed,
  menu,
}: ILayoutHeader) => {
  return (
    <>
      <Layout.Header className="basicHeader">
        <Row
          justify="space-between"
          align="middle"
          className="basicHeader__wrapper--desktop"
        >
          {!authenticated && componentLogo}
          {componentButtons}
          {componentCustomMenu}
        </Row>
        {showDrawerMenu && (
          <Row
            align="middle"
            className="basicHeader__wrapper--mobile"
            onClick={toggleHeaderFixed}
          >
            <DrawerMenu loggedMenu={menu} />
          </Row>
        )}
      </Layout.Header>
    </>
  );
};

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const BasicHeader: NextPage<Props, unknown> = ({
  authenticated,
  accessToken,
  cartItems,
  userPoints,
  showBagMenu,
  getRedeemPointMagentoCart,
  impersonate,
  isUSAPlatform,
  privateMenu,
  publicMenu,
  user,
  userStatus,
  targetURL,
  isLoggingIn,
  commercialDisclaimers,
  maxSaleRestrictions,
  redeemedProductsByTime,
  addToast,
}): JSX.Element => {
  const [nItemsCart, setnItemsCart] = useState(0);
  const [menu, setMenu] = useState<IMenu[]>(
    getMenu(authenticated, publicMenu, privateMenu, userStatus)
  );
  const [isVisible, setIsVisible] = useState(true);
  const { formatMessage, formatNumber } = useIntl();
  const router = useRouter();
  const { publicRuntimeConfig } = getConfig();
  const { PLATFORM_PREFIX } = publicRuntimeConfig;
  const [pathname, setPathname] = useState<string>('');
  const [isMobile, setIsMobile] = useState(false);
  const showCompleteProfile = useShowCompleteProfile(user);
  const userTotalProducts = redeemedProductsByTime?.annual;
  const maxTotalProductsByPeriod =
    maxSaleRestrictions?.annual.maxTotalProductsByPeriod;
  const isAnnualLimitExceded =
    userTotalProducts &&
    maxTotalProductsByPeriod &&
    Number(maxTotalProductsByPeriod) <= Number(userTotalProducts);

  useEffect(() => {
    if (typeof window != 'undefined') {
      const replacedPath = window.location.pathname.replace(
        PLATFORM_PREFIX,
        ''
      );
      setPathname(replacedPath);
    }
  });

  let prevScrollPos: number;

  const isButtonHiden = (buttonName: string) => {
    if (router.route === routePaths.INDEX) return false;

    return router.route
      .toLocaleLowerCase()
      .includes(buttonName.toLocaleLowerCase());
  };

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    let visible = true;

    switch (true) {
      // Scroll UP
      case prevScrollPos > currentScrollPos:
        visible = true;
        break;
      // Scroll DOWN
      case currentScrollPos > prevScrollPos:
        visible = currentScrollPos <= MIN_SCROLL_POSITION;
        break;
    }

    prevScrollPos = currentScrollPos;
    setIsVisible(visible);
  };

  /* ---------- COMPONENTS REGION ----------*/
  const componentLogo = (
    <a
      onClick={() => {
        redirectTo(routePaths.INDEX);
      }}
    >
      <YuLogoSecondary size="M"></YuLogoSecondary>
    </a>
  );

  const componentButtons = !authenticated && (
    <div style={{ display: 'flex', gap: '32px' }}>
      <PlatformSelector />

      <Row style={{ gap: '16px', width: 'auto' }}>
        <Link href={routePaths.PAGES.SIGNUP} as="/login">
          <Button
            className={`btn-primary btn-primary--black--small ${
              isButtonHiden('register') ? 'btn-hidden' : ''
            }`}
            disabled={isLoggingIn}
          >
            <FormattedMessage id="app.register.header" />
          </Button>
        </Link>

        <Link href={routePaths.PAGES.LOGIN} as="/register">
          <Button
            type="primary"
            className={`btn-primary btn-primary--white--small ${
              isButtonHiden('login') ? 'btn-hidden' : ''
            }`}
            disabled={isLoggingIn}
          >
            <FormattedMessage id="app.sign-in.header" />
          </Button>
        </Link>
      </Row>
    </div>
  );

  const componentCustomMenu = authenticated && (
    <BasicMenu
      className="menu"
      menu={menu}
      mode="horizontal"
      userStatus={userStatus}
    />
  );

  const componentImpersonate = impersonate && !targetURL && (
    <Row justify="center" align="middle" className="layout__impersonate">
      <span>
        You are logged as <strong>{user.email}</strong>
      </span>
    </Row>
  );

  const commercialMessages = (): React.ReactNode[] => {
    const _commercialMessages: React.ReactNode[] = [];

    if (showCompleteProfile) {
      _commercialMessages.push(
        <div style={{ display: 'flex', gap: '8px' }}>
          <YuIcon name="Identification" size="M" />
          <span>
            {customFormatMessage(
              'notification.commercial-disclaimer.complete-profile',
              {
                complete_profile: (chunks: React.ReactNode) => (
                  <Link href={routePaths.PAGES.MY_ACCOUNT.PROFILE_EXTRA_FIELDS}>
                    <a>{chunks}</a>
                  </Link>
                ),
              }
            )}
          </span>
        </div>
      );
    }

    commercialDisclaimers.forEach((message) =>
      _commercialMessages.push(message.message)
    );

    return _commercialMessages;
  };

  const userStatusMessage = (): string => {
    let message: string = null;

    switch (userStatus) {
      case UserStatusEnum.ACCOUNT_NOT_VALIDATED:
      case UserStatusEnum.MANAGER_NOT_VALIDATED:
      case UserStatusEnum.MIGRATE_PENDING:
        message = formatMessage({ id: 'home.header.no-validated' });
        break;

      case UserStatusEnum.NO_CENTER:
        message = formatMessage({ id: 'home.header.no-center' });
        break;

      case UserStatusEnum.HCP_NOT_VALIDATED:
      case UserStatusEnum.STAFF_NOT_VALIDATED:
        message = formatMessage({
          id: isUSAPlatform
            ? 'home.header.employee-pending-usa'
            : 'home.header.employee-pending',
        });

        break;
      default:
        break;
    }

    return message;
  };

  const _commercialMessages = [...commercialMessages()];

  const headerClassName =
    'container--no-padding basicHeader__container basicHeader__container-fixed';

  const buildNavigationItems = (menu: IMenu[]): HeaderNavItemProps[] => {
    const isDisabled = (item: IMenu): boolean => {
      return [
        routePaths.PAGES.REQUEST_ORDER,
        routePaths.PAGES.REDEEM_POINTS.MAIN,
      ].includes(item.route) && !requestOrderStatus.includes(userStatus)
        ? true
        : item.disabled || false;
    };

    return menu
      .filter((menuItem) => menuItem.visible)
      .map((menuItem) => {
        return {
          href: menuItem.route,
          useNext: !menuItem.absolute,
          disabled: isDisabled(menuItem),
          label: menuItem.name,
          nextAs: menuItem.absolute
            ? menuItem.route
            : `${PLATFORM_PREFIX}${menuItem.route}`,
        };
      });
  };

  const toggleHeaderFixed = () => {
    setIsVisible(true);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    setMenu(getMenu(authenticated, publicMenu, privateMenu, userStatus));
  }, [authenticated, publicMenu, privateMenu]);

  useEffect(() => {
    if (authenticated) {
      getRedeemPointMagentoCart();
    }
  }, [authenticated]);

  useEffect(() => {
    if (user) {
      const toastProps = getToastProps(user);
      if (toastProps) {
        setTimeout(() => addToast(toastProps), 1000);
      }
    }
  }, [user]);

  if (userStatusMessage()) {
    _commercialMessages.unshift(userStatusMessage());
  }

  const getContactRoute = (): string => {
    const haveDelegate = user?.salesforce?.centerDelegates?.length;
    const { CONTACT_REP, LOGIN } = routePaths.PAGES;
    const { CONTACT_US } = routePaths.FOOTER;

    if (!accessToken) return LOGIN;

    return haveDelegate && shouldGoToDelegatePage() ? CONTACT_REP : CONTACT_US;
  };

  useEffect(() => {
    const qty: number = cartItems.reduce(
      (acc: number, currentItem: IRedeemPointsProduct) => {
        return acc + currentItem.qty;
      },
      0
    );

    setnItemsCart(qty);
  }, [cartItems]);

  const YumaHeader = () => {
    return (
      <YumaHeaderStyled
        initialState={{
          header: { hasSearch: false },
        }}
        navbar={{
          logo: {
            href: routePaths.INDEX,
            nextAs: `${PLATFORM_PREFIX}${routePaths.INDEX}`,
          },
          leftSection: {
            links: [
              {
                prependIcon: 'Mail',
                standalone: true,
                href: getContactRoute(),
                label: formatMessage({ id: 'menu.header.contact' }),
                nextAs: `${PLATFORM_PREFIX}${getContactRoute()}`,
              },
            ],
          },
          rightSection: {
            account: {
              userName: `${
                user?.userMainData?.name ? user.userMainData.name : ''
              } ${
                user?.userMainData?.lastname ? user.userMainData.lastname : ''
              }`,
              link: {
                href: routePaths.PAGES.MY_ACCOUNT.ACCOUNT_INDEX,
                nextAs: `${PLATFORM_PREFIX}${routePaths.PAGES.MY_ACCOUNT.ACCOUNT_INDEX}`,
              },
              menu: {
                options: isUSAPlatform
                  ? [
                      {
                        icon: 'User',
                        label: formatMessage({ id: 'menu.header.my-account' }),
                        href: routePaths.PAGES.MY_ACCOUNT.MY_PROFILE,
                        nextAs: `${PLATFORM_PREFIX}${routePaths.PAGES.MY_ACCOUNT.MY_PROFILE}`,
                      },
                      {
                        icon: 'Coins',
                        label: formatMessage({
                          id: 'menu.section.personal.points',
                        }),
                        href: routePaths.PAGES.MY_ACCOUNT.MY_POINTS,
                        nextAs: `${PLATFORM_PREFIX}${routePaths.PAGES.MY_ACCOUNT.MY_POINTS}`,
                      },
                      {
                        icon: 'Box',
                        label: formatMessage({
                          id: 'menu.section.personal.my_orders',
                        }),
                        href: routePaths.PAGES.MY_ACCOUNT.ORDERS,
                        nextAs: `${PLATFORM_PREFIX}${routePaths.PAGES.MY_ACCOUNT.ORDERS}`,
                      },
                      {
                        icon: 'Bank',
                        label: formatMessage({
                          id: 'menu.section.center.profile',
                        }),
                        href: routePaths.PAGES.MY_CENTER.CENTER_PROFILE,
                        nextAs: `${PLATFORM_PREFIX}${routePaths.PAGES.MY_CENTER.CENTER_PROFILE}`,
                      },
                    ]
                  : [
                      {
                        icon: 'User',
                        label: formatMessage({ id: 'menu.header.my-account' }),
                        href: routePaths.PAGES.MY_ACCOUNT.MY_PROFILE,
                        nextAs: `${PLATFORM_PREFIX}${routePaths.PAGES.MY_ACCOUNT.MY_PROFILE}`,
                      },
                      {
                        icon: 'Coins',
                        label: formatMessage({
                          id: 'menu.section.personal.points',
                        }),
                        href: routePaths.PAGES.MY_ACCOUNT.MY_POINTS,
                        nextAs: `${PLATFORM_PREFIX}${routePaths.PAGES.MY_ACCOUNT.MY_POINTS}`,
                      },
                      {
                        icon: 'Location',
                        label: formatMessage({
                          id: 'menu.section.personal.addresses',
                        }),
                        href: routePaths.PAGES.MY_ACCOUNT.MY_ADDRESSES,
                        nextAs: `${PLATFORM_PREFIX}${routePaths.PAGES.MY_ACCOUNT.MY_ADDRESSES}`,
                      },
                      {
                        icon: 'Box',
                        label: formatMessage({
                          id: 'menu.section.personal.my_orders',
                        }),
                        href: routePaths.PAGES.MY_ACCOUNT.ORDERS,
                        nextAs: `${PLATFORM_PREFIX}${routePaths.PAGES.MY_ACCOUNT.ORDERS}`,
                      },
                    ],
                title:
                  typeof userPoints === 'number' &&
                  formatMessage(
                    { id: 'menu.header.points' },
                    { points: formatNumber(userPoints) }
                  ),
              },
            },
            cart: {
              disabled: !redeemPointStatus.includes(userStatus),
              itemCount: nItemsCart,
              onClick: () => showBagMenu(true),
            },
          },
        }}
        navigation={{ items: buildNavigationItems(menu) }}
      ></YumaHeaderStyled>
    );
  };
  const redeemPointsDetailsRegex = /^\/redeem-points\/\d+$/;
  const DrawerMenuHeader = () => {
    return (
      <Layout.Header className="basicHeader">
        <Row align="middle" className="" onClick={toggleHeaderFixed}>
          <DrawerMenu loggedMenu={menu} />
        </Row>
      </Layout.Header>
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      className={`header-shadow header-fixed ${headerClassName} ${
        isVisible ? '' : 'header-fixed--hidden'
      }`}
    >
      <ConditionalRenderer
        condition={authenticated && _commercialMessages.length > 0}
        trueComponent={
          <CommercialDisclaimer
            messages={_commercialMessages}
            messagesDelay={8000}
          />
        }
      />
      {componentImpersonate}
      <div className="container">
        {authenticated && !isMobile && <YumaHeader />}
        {authenticated && isMobile && <DrawerMenuHeader />}

        {!authenticated && !isMobile && (
          <LayoutHeader
            showDrawerMenu={false}
            authenticated={authenticated}
            componentLogo={componentLogo}
            componentButtons={componentButtons}
            componentCustomMenu={componentCustomMenu}
            menu={menu}
            toggleHeaderFixed={toggleHeaderFixed}
          />
        )}
        {!authenticated && isMobile && (
          <LayoutHeader
            showDrawerMenu={true}
            authenticated={authenticated}
            componentLogo={componentLogo}
            componentButtons={componentButtons}
            componentCustomMenu={componentCustomMenu}
            menu={menu}
            toggleHeaderFixed={toggleHeaderFixed}
          />
        )}
      </div>

      {isAnnualLimitExceded &&
        (pathname === routePaths.PAGES.REDEEM_POINTS.MAIN ||
          redeemPointsDetailsRegex.test(pathname)) && (
          <Disclaimer
            pathname={pathname}
            extraDisclaimerName="maxAnnualProducts"
          />
        )}
      <Disclaimer pathname={pathname} />
    </div>
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    user: state.auth.user,
    userPoints: state.auth.userPoints,
    accessToken: state.auth.accessToken,
    authenticated:
      state.auth.accessToken !== null && state.auth.accessToken !== '',
    impersonate: state.auth.impersonate,
    publicMenu: state.app.publicMenu,
    privateMenu: state.app.userMenu,
    userStatus: state.auth.userStatus,
    isUSAPlatform: state.platform.isUSAPlatform,
    targetURL: state.auth.impersonateTargetURL,
    isLoggingIn: state.app.isLoggingIn,
    commercialDisclaimers: state.app.disclaimers.commercial,
    cartItems: state.app.redeemPoints.cart.items,
    userMenus: state.app.userMenu,
    maxSaleRestrictions:
      state.auth.user?.magento.orderRestrictions.salesRestrictions
        .maxSaleRestrictions,
    redeemedProductsByTime:
      state.auth.user?.magento.orderRestrictions.redeemedProductsByTime,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    { getRedeemPointMagentoCart, showBagMenu, addToast },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(BasicHeader);
