import { SkeletonChallengeCard } from '../ChallengeCard.skeleton';
import { YuFlex } from '@isdin/yuma-react-web-pin';

const SkeletonCampaignCards = (props): JSX.Element => {
  return (
    <YuFlex {...props} className="campaign-skeleton-grid">
      <SkeletonChallengeCard />
      <SkeletonChallengeCard />
      <SkeletonChallengeCard />
      <SkeletonChallengeCard />
      <SkeletonChallengeCard />
      <SkeletonChallengeCard />
    </YuFlex>
  );
};

export default SkeletonCampaignCards;
