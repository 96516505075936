import routePaths from 'config/routes';
import { NextPage } from 'next';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { getDataCall } from 'services/api';
import apiPaths from 'services/apiPaths';
import { privateRoute } from 'services/privateRoute';
import {
  fetchTalks,
  insertChallenges,
  updateChallenges,
} from 'src/actions/appActions';
import { EmptyList } from 'src/components';
import SubHeader from 'src/components/SubHeader';
import BuildPageChallenge, {
  showMoreButton,
} from 'src/components/challenge/ChallengeBuildPage';
import ChallengeCard from 'src/components/challenge/ChallengeCard';
import { builderChallenge } from 'src/components/challenge/builder/BuilderChallenge';
import TypeformNPS from 'src/components/typeforms/nps/TypeFormNPS';
import { IRootReducers } from 'src/reducers';
import { ChallengedTabsEnum, ChallengeTypeEnum } from 'src/shared/enums';
import {
  ChallengeCardButton,
  ChallengeDetail,
  IChallenge,
  IChallengesList,
  IResponseChallengesList,
} from 'src/shared/models';
import { ReduxPageContext } from 'src/store';
import {
  fetchInitialData,
  getTranslatedData,
  renderChallengeCard,
} from '../../../utils';

const PAGE_SIZE = 4;

type PageProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    initialData: IChallengesList;
  };

export const renderChallengeTalk = (
  challenge: ChallengeDetail,
  isLinkedChallenge: boolean
): JSX.Element => {
  const { formatMessage } = useIntl();
  const { challengeEvents, challengeType } = challenge;
  const mainEvent = challengeEvents.find((e) => e.isMain);

  const getChallengePath = (): string => {
    switch (challengeType) {
      case ChallengeTypeEnum.TALK:
        return routePaths.PAGES.TALK_DETAIL;

      case ChallengeTypeEnum.CONGRESS:
        return routePaths.PAGES.CONGRESS_DETAIL;

      default:
        throw new Error('Unknown challenge type');
    }
  };

  let userStatus: { idResource: number; status: number };
  let challengeCardButton: ChallengeCardButton = {
    className: 'challengeCard__link challengeCard__link--active',
    path: getChallengePath(),
    title: formatMessage({ id: 'talk.see' }),
  };

  const performSubscription =
    !mainEvent?.inscription.isPerformed && mainEvent?.inscription.isAvailable;
  const seeTheEvent = mainEvent?.status.isInProgress;

  switch (true) {
    case mainEvent?.inscription.isPerformed:
    case seeTheEvent:
      challengeCardButton = {
        ...challengeCardButton,
        title: formatMessage({ id: 'talk.see' }),
      };
      break;
    case performSubscription:
      challengeCardButton = {
        ...challengeCardButton,
        title: formatMessage({
          id: mainEvent?.status.isFull
            ? 'talk.inscribe.waiting-list'
            : 'talk.inscribe',
        }),
      };
      break;
  }

  const config: ChallengeDetail = {
    ...challenge,
    challengeName: getTranslatedData(challenge, 'name'),
    challengeDescription:
      getTranslatedData(challenge, 'description') ||
      getTranslatedData(challenge, 'descriptionLong'),
    challengeCardButton,
    userStatus,
    isLinkedChallenge,
  };

  return <ChallengeCard {...config} />;
};

const TalksPage: NextPage<PageProps> = ({
  eventsList,
  user,
  totalEvents,
  updateChallenges,
  fetchTalks,
}): JSX.Element => {
  const getCurrentEventsListPage = () =>
    Math.floor(eventsList.length / PAGE_SIZE);

  const [events, setEvents] = useState<ChallengeDetail[]>();

  const [filtersEvents, setFiltersEvents] = useState<ChallengedTabsEnum>(
    ChallengedTabsEnum.ALL
  );

  const [canShowMoreEvents, setCanShowMoreEvents] = useState<boolean>(
    totalEvents > eventsList.length
  );

  const [isLoadingMoreEvents, setIsLoadingMoreEvents] =
    useState<boolean>(false);

  const [requestPage, setRequestPage] = useState<number>(
    getCurrentEventsListPage
  );

  const handleShowMoreEvents = async () => {
    setIsLoadingMoreEvents(true);

    const request = `${apiPaths.CHALLENGES.GET_EVENTS}?page=${requestPage}&size=${PAGE_SIZE}`;

    try {
      const response = await getDataCall({
        dataPath: request,
        callConfig: {},
      });

      const ids = new Set(eventsList.map((event) => event.challengeId));
      const newchallengeAcc: ChallengeDetail[] = [
        ...eventsList,
        ...response.data.challenges
          .filter((event: IChallenge) => !ids.has(event.idChallenge))
          .map((event: IChallenge) => builderChallenge(event, user)),
      ];

      updateChallenges({
        challengeType: 'events',
        challengeList: {
          total: response.data.total,
          challenges: newchallengeAcc,
        },
      });
      setRequestPage(requestPage + 1);
      const eventsLeft = response.data.total - eventsList.length;
      setCanShowMoreEvents(eventsLeft > response.data.challenges.length);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingMoreEvents(false);
    }
  };

  const renderEvents = (): JSX.Element | JSX.Element[] => {
    let filteredEvents: ChallengeDetail[] = [];

    if (!events?.length) return <EmptyList page_name="eventsCongresses" />;

    switch (filtersEvents) {
      case ChallengedTabsEnum.ATTENDED:
        events.forEach((_event) => {
          // TODO: use isCampaignCompleted and align
          const mainEvent = _event.challengeEvents.find((c) => c.isMain);
          if (
            mainEvent?.inscription.isPerformed &&
            mainEvent?.attend.isPerformed
          )
            filteredEvents.push(_event);
        });
        break;
      case ChallengedTabsEnum.FAVORITES:
        filteredEvents = events.filter((_event) => _event.challengeLikes.liked);
        break;
      case ChallengedTabsEnum.REGISTERED:
        events.forEach((_event) => {
          const mainEvent = _event.challengeEvents.find((c) => c.isMain);
          if (
            mainEvent?.inscription.isPerformed &&
            !mainEvent?.attend.isPerformed
          )
            filteredEvents.push(_event);
        });
        break;
      default:
        filteredEvents = events;
        break;
    }

    if (!filteredEvents.length)
      return <EmptyList page_name="eventsCongresses" />;

    return filteredEvents.map((challenge: ChallengeDetail) =>
      renderChallengeCard({ challenge })
    );
  };

  useEffect(() => {
    fetchTalks(user, [], eventsList.length);
  }, []);

  useEffect(() => {
    setEvents(eventsList);
    setCanShowMoreEvents(totalEvents > eventsList.length);
  }, [eventsList]);

  const showMoreComponentEvents =
    filtersEvents == ChallengedTabsEnum.ALL
      ? showMoreButton(
          `page.marketing-materials.show-more`,
          isLoadingMoreEvents,
          canShowMoreEvents,
          handleShowMoreEvents
        )
      : null;

  return (
    <>
      <SubHeader />
      <div>
        <BuildPageChallenge
          pageClassName="talksPage"
          onChangeTab={setFiltersEvents}
          renderChallenges={renderEvents}
          showMoreComponent={showMoreComponentEvents}
          customIdDivFilters="talksPage--hided-by-filter"
          shouldRenderTabs={events?.length > 0}
          challengeType={ChallengeTypeEnum.TALK}
        />
      </div>
      <div className="ant-row">
        <TypeformNPS user={user} />
      </div>
    </>
  );
};

const initialAction = async (
  accessToken: string,
  ctx: ReduxPageContext
): Promise<{ data: IChallengesList | null }> => {
  const state: IRootReducers = ctx.store.getState();

  const savedEvents = state.app.challenges.events;

  let events: IChallengesList = {
    total: savedEvents.total,
    challenges: savedEvents.challenges,
  };

  try {
    const areLoadedEvents = savedEvents.total > 0;

    if (!areLoadedEvents) {
      const responseEvents: IResponseChallengesList = await fetchInitialData(
        accessToken,
        `${apiPaths.CHALLENGES.GET_EVENTS}?size=${8}`,
        ctx
      );
      const eventsList: ChallengeDetail[] = responseEvents.challenges.map(
        (challengesEvents: IChallenge) =>
          builderChallenge(challengesEvents, state.auth.user)
      );

      events = {
        total: responseEvents.total,
        challenges: eventsList,
      };
    }

    if (ctx.store.dispatch) {
      insertChallenges({
        challengeList: events,
        challengeKey: 'events',
      })(ctx.store.dispatch);
    }

    return { data: events };
  } catch (error) {
    console.error('SERVER ERROR: ', error);
    return { data: null };
  }
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    user: state.auth.user,
    eventsList: state.app.challenges.events.challenges,
    totalEvents: state.app.challenges.events.total,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      updateChallenges,
      fetchTalks,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(privateRoute({ WrappedComponent: TalksPage, initialAction }));
