import { Button, Col, Row } from 'antd';
import { default as React, FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ChallengeResourceWrapper, {
  ChallengeResourceWrapper_Blocked,
} from 'src/components/challenge/ChallengeResourceWrapper';
import CustomModal from 'src/components/CustomModal';
import { IChallenge, IChallengeGenericButton } from 'src/shared/models';

import { config as configAPP } from 'config/config';
import { completeChallengeUserResource } from '../../../utils';

interface OwnProps {
  children?: React.ReactNode;
  config: IChallengeGenericButton;
  idResource: number;
  updateUserPoints: (userPoints: number) => void;
  updateChallenge: (challengeData: IChallenge) => void;
}

const ChallengeGenericButton: FC<OwnProps> = ({
  config,
  idResource,
  updateUserPoints,
  updateChallenge,
}) => {
  if (!config) return <React.Fragment />;

  const {
    title,
    modalDescription,
    showModal,
    modalTitle,
    wasClicked,
    labelCompleted,
    description,
    showSeparator,
  } = config;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorLabel, setErrorLabel] = useState<string>(null);

  const modalContent = (
    <Col span={24} className="generic-button-modal__header">
      <h4 className="generic-button-modal__header--title h4">
        {errorLabel ? (
          <FormattedMessage id={'center-requests.error.confirm-action'} />
        ) : (
          modalTitle
        )}
      </h4>
      <p className="generic-button-modal__header--description">
        {errorLabel ? errorLabel : modalDescription}
      </p>
    </Col>
  );

  const onClickButton = async () => {
    try {
      setLoading(true);
      setIsModalVisible(true);
      setErrorLabel(null);

      await completeChallengeUserResource({
        idResource,
        updateChallenge,
        updateUserPoints,
      });

      setIsModalVisible(showModal);
      setTimeout(() => {
        setLoading(false);
      }, 200);
    } catch (error) {
      console.error({ error });
      setErrorLabel(error.message);
      setLoading(false);
    }
  };

  const modal = (
    <CustomModal
      {...{
        loading,
        visible: isModalVisible,
        centered: true,
        content: modalContent,
        onCancel: () => setIsModalVisible(false),
        footer: null,
        showIcon: !!errorLabel,
        icon: `${configAPP.APP.ASSET_PREFIX}/process/cross.svg`,
        customClass: 'generic-button-modal',
      }}
    />
  );

  const component = (
    <>
      <ChallengeResourceWrapper_Blocked />
      <Row className="generic-buttom" justify="center">
        {wasClicked ? (
          <Row justify="center" align="middle">
            <div
              className={`challengeResourceWrapper__label challengeResourceWrapper__label--completed`}
            >
              {labelCompleted}
            </div>
          </Row>
        ) : (
          <Button
            className={'btn-primary btn-primary--black'}
            onClick={() => onClickButton()}
          >
            {title}
          </Button>
        )}
      </Row>
      {modal}
    </>
  );

  return (
    <ChallengeResourceWrapper
      children={component}
      icon={config.icon}
      styleClass={`challengeDetail--wys-wyg ${
        !showSeparator && 'generic-button-no-separator '
      }`}
      title=""
      description={description}
    />
  );
};

export default ChallengeGenericButton;
