import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  commercialModalVisibility,
  customFormatMessage,
  isDEPlatform,
} from '../../../utils';
import _ from 'lodash';
import {
  YuButton,
  YuFlex,
  YuIllustration,
  YuLink,
  YuModal,
  YuModalContent,
  YuParagraph,
  YuResourceList,
  YuSpacer,
} from '@isdin/yuma-react-web-pin';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { addToast } from 'src/actions/toastActions';
import { IRootReducers } from 'src/reducers';
import { useRouter } from 'next/router';
import { fetchCommercialSubscriptionRecruitmentDate } from 'src/actions/appActions';
import { postDataCall } from 'services/api';
import apiPaths from 'services/apiPaths';
import {
  getUserClosedCommercialModalDate,
  updateMailSubscriptionStatus,
} from 'src/actions/authActions';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();
const { PLATFORM_PREFIX } = publicRuntimeConfig;

const CustomYuButton = styled(YuButton)`
  white-space: normal;
  font-size: 0.875rem;
  height: 40px;
`;

const CustomYuResourceList = styled(YuResourceList)`
  justify-content: center;

  div {
    gap: 8px;
  }

  svg,
  i {
    width: 24px;
    height: 24px;
  }

  li {
    text-align: left;
  }
`;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const ModalPlatformCommunicationsComponent = ({
  user,
  accountCreateDate,
  commercialSubscriptionRecruitment,
  userCommercialSubscriptionStatus,
  userCommercialUnsubscriptionDate,
  modalCommercialClosedDate,
  fetchCommercialSubscriptionRecruitmentDate,
  getUserClosedCommercialModalDate,
  updateMailSubscriptionStatus,
  addToast,
  tokenCommercialSubscritionStatus,
}: Props): JSX.Element => {
  const { formatMessage } = useIntl();
  const [showModal, setShowModal] = useState<boolean>(true);
  const [accepting, setAccepting] = React.useState<boolean>(false);

  const { query } = useRouter();

  const onClose = async () => {
    try {
      await postDataCall({
        dataPath: apiPaths.NEWSLETTER_SUBSCRIPTION.COMMERCIAL_MODAL_CLOSED,
        data: { commercialSubscriptionStatus: true },
        callConfig: {},
      });
    } catch (error) {
      console.error(
        `An error occurred while trying to register commercial modal closure for user ${user.uuidUser}: ${error.message}`
      );
    } finally {
      setShowModal(false);
    }
  };

  const handleLoadInitialData = async () => {
    if (user) {
      await Promise.all([
        getUserClosedCommercialModalDate(),
        fetchCommercialSubscriptionRecruitmentDate(
          'commercialSubscriptionRecruitment'
        ),
      ]);
    }
  };

  const send = async () => {
    try {
      setAccepting(true);
      await updateMailSubscriptionStatus(true);
      setShowModal(false);
      addToast({
        message: formatMessage({
          id: isDEPlatform
            ? 'page.account.newsletter.subscribe'
            : 'communications-toast-enabled',
        }),
        status: isDEPlatform ? 'info' : 'success',
      });
    } catch (e) {
      addToast({
        message: formatMessage({ id: 'communications-toast-error' }),
        status: 'error',
      });
    } finally {
      setAccepting(false);
    }
  };

  const handleCommercialModalVisibility = (): boolean => {
    if (tokenCommercialSubscritionStatus === null) return false;
    const isVisible = commercialModalVisibility(
      userCommercialSubscriptionStatus,
      commercialSubscriptionRecruitment.date,
      userCommercialUnsubscriptionDate,
      accountCreateDate,
      Number(commercialSubscriptionRecruitment.days),
      modalCommercialClosedDate,
      tokenCommercialSubscritionStatus
    );

    return isVisible && showModal;
  };

  const modalContent = (
    <YuModalContent>
      <YuFlex flexDirection="column" alignItems="center">
        <YuIllustration name="Email" size="XS" />
        <div className="title">
          <FormattedMessage id="communications-modal.title" />
        </div>
        <YuSpacer size="M" />
        <div className="regular-body" style={{ maxWidth: '400px' }}>
          <CustomYuResourceList
            className="resource-list"
            size="XL"
            items={[
              {
                prependIcon: 'CheckmarkCircle',
                title: (
                  <FormattedMessage id="communications-modal.body-list-1" />
                ),
              },
              {
                prependIcon: 'CheckmarkCircle',
                title: (
                  <FormattedMessage id="communications-modal.body-list-2" />
                ),
              },
              {
                prependIcon: 'CheckmarkCircle',
                title: (
                  <FormattedMessage id="communications-modal.body-list-3" />
                ),
              },
            ]}
          />
        </div>
        <YuSpacer size="L" />
        <YuFlex className="buttons-row">
          <CustomYuButton variant="secondary" size="L" onClick={onClose}>
            <FormattedMessage id="communications-modal.button.exit" />
          </CustomYuButton>

          <CustomYuButton size="L" onClick={send} prependLoading={accepting}>
            <FormattedMessage id="communications-modal.button.accept" />
          </CustomYuButton>
        </YuFlex>
        <YuSpacer size="L" />
        <YuFlex gap="XXXS">
          <YuParagraph size="S">
            {customFormatMessage('communications-modal.policy1')}
          </YuParagraph>
          <YuLink
            targetBlank
            href="/privacy-policy"
            nextAs={`${PLATFORM_PREFIX}/privacy-policy`}
            weight="sbold"
            size="S"
          >
            {customFormatMessage('communications-modal.policy2')}
          </YuLink>
        </YuFlex>
      </YuFlex>
    </YuModalContent>
  );

  useEffect(() => {
    handleLoadInitialData();
  }, []);

  if (
    !showModal ||
    (_.isString(query?.page) &&
      ['/terms-conditions', '/privacy-policy', '/cookie-policy'].includes(
        query.page.toString()
      ))
  )
    return null;

  return (
    <YuModal
      behaviour={{
        close: { onClickOutArea: false },
      }}
      isOpen={handleCommercialModalVisibility()}
      fullScreen={false}
      className="modal-commercial-communications"
      closable={true}
      onClose={onClose}
    >
      {modalContent}
    </YuModal>
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    user: state.auth.user,
    accountCreateDate: state.auth.user?.createDate,
    commercialSubscriptionRecruitment:
      state.app.commercialSubscriptionRecruitment,
    userCommercialSubscriptionStatus:
      state.auth.user?.salesforce.mailSubscription.status,
    userCommercialUnsubscriptionDate:
      state.auth.user?.salesforce.mailSubscription.unsubscriptionDate,
    modalCommercialClosedDate:
      state.auth.user?.salesforce.mailSubscription.commercialModalClosedDate,
    tokenCommercialSubscritionStatus:
      state.auth.userCommercialSubscriptionTokenStatus,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      addToast,
      fetchCommercialSubscriptionRecruitmentDate,
      updateMailSubscriptionStatus,
      getUserClosedCommercialModalDate,
    },
    dispatch
  );

const ModalPlatformCommunications = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalPlatformCommunicationsComponent);

export default ModalPlatformCommunications;
