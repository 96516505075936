import { IResourceList } from 'src/components/challenge/ChallengeInterfaces';
import {
  ClauseStatusEnum,
  ResourceTypeDetailEnum,
  ResourceTypeEnum,
} from 'src/shared/enums';
import { IChallengeClause, IChallengeLegalCheck } from 'src/shared/models';

import { builderLegalCheck } from './BuilderLegalCheck';
import { checkUserStatus } from '../../../../utils';

const { LEGAL_CHECK } = ResourceTypeEnum;
const { SIGNED_PIN, SIGNED_EMBRACE, SIGNED_CASCADE } = ClauseStatusEnum;

const { CLAUSE_COMPLETED_MESSAGE, CLAUSE_ID, CLAUSE_BUTTON_TEXT } =
  ResourceTypeDetailEnum;

export const buildClause = (resource: IResourceList): IChallengeClause => {
  const { idResource } = resource;

  let challengeClause: IChallengeClause;
  let onCompleteClauseMessage: string;
  let clauseLegalCheck: IChallengeLegalCheck;
  let clauseId: string;
  let buttonText: string;

  resource.resourceDetailList
    .filter(({ status }) => status)
    .forEach(({ idResourceTypeD: { idResourceTypeD }, value }) => {
      switch (idResourceTypeD) {
        case CLAUSE_COMPLETED_MESSAGE:
          onCompleteClauseMessage = value;
          break;
        case CLAUSE_ID:
          clauseId = value;
          break;
        case CLAUSE_BUTTON_TEXT:
          buttonText = value;
          break;
      }
    });

  resource.resourceList
    .filter(({ status }) => status)
    .forEach((resource: IResourceList) => {
      switch (resource.idResourceType.idResourceType) {
        case LEGAL_CHECK:
          clauseLegalCheck = builderLegalCheck(resource);
          break;
      }
    });

  return {
    ...challengeClause,
    onCompleteClauseMessage,
    clauseLegalCheck,
    clauseId,
    buttonText,
    idResource,
    isCompleted: checkUserStatus(resource, [
      SIGNED_PIN,
      SIGNED_EMBRACE,
      SIGNED_CASCADE,
    ]),
  };
};
