import axios from 'axios';
import { config } from 'config/config';
import routePaths, { publicPaths } from 'config/routes';
import { serialize } from 'cookie';
import Router from 'next/router';
import { Dispatch } from 'redux';
import * as api from 'services/api';
import apiPaths from 'services/apiPaths';
import redirectTo from 'services/redirectTo';
import { getPublicMenus } from 'src/components/menu/Menu.utils';
import { authTypes } from 'src/types/authTypes';
import {
  getCookie,
  getUserStatus,
  isDEPlatform,
  isUSAPlatform,
  removeCookieFromClient,
  removeCookiesFromServer,
  setCookieFromClient,
  setCookiesFromServer,
} from '../../utils';
import { IRootReducers } from '../reducers';
import { UserStatusEnum } from '../shared/enums';
import {
  defaultOrderRestrictions,
  ICustomer,
  IExpirationPointsInfo,
  IUser,
  IUserMainData,
} from '../shared/models';
import { ReduxPageContext } from '../store';
import { appTypes } from '../types/appTypes';
import {
  fetchClientUserMenu,
  fetchDisclaimers,
  fetchProductsLimit,
  fetchServerDisclaimers,
  fetchServerUserMenu,
} from './appActions';
import { NextPageContext } from 'next';

const { BASE_URL } = config.API;
const { ACCESS_TOKEN, BASE_COOKIE_DOMAIN, REFRESH_TOKEN, UUID_USER } =
  config.COOKIES;
const {
  INDEX,
  PAGES: { LOGIN, LOGOUT },
} = routePaths;

/*-------------- AUTH INTERFACES --------------- */

interface IAuthorizeUser {
  type: authTypes.AUTH_USER_LOGGED;
  payload: {
    accessToken: string;
    refreshToken: string;
    user: IUser;
    userPoints: number;
    userStatus: UserStatusEnum;
  };
}

interface IUserLogout {
  type: authTypes.AUTH_LOGOUT;
}

interface IUserDeclined {
  type: authTypes.AUTH_USER_DECLINED;
  payload: {
    userStatus: UserStatusEnum;
  };
}

interface IImpersonate {
  type: authTypes.AUTH_IMPERSONATE;
  payload: {
    accessToken: string;
    refreshToken: string;
    impersonate: boolean;
    user: IUser;
    userPoints: number;
    userStatus: UserStatusEnum;
  };
}

export interface SetUserDataUpdate {
  type: authTypes.AUTH_UPDATE_USER_DATA;
  payload: { user: IUser };
}

interface SetTokens {
  type: authTypes.AUTH_SET_TOKENS;
  payload: { accessToken: string; refreshToken: string };
}

interface SetInitialUserInfo {
  type: authTypes.AUTH_SET_INITIAL_USER;
  payload: {
    user: IUser;
    userPoints: number;
    accessToken?: string;
    refreshToken?: string;
    userStatus: UserStatusEnum;
  };
}

interface SetNewsletterSubscription {
  type: authTypes.AUTH_UPDATE_NEWSLETTER_SUBSCRIPTION;
  payload: { newsletterSubscription: boolean };
}

interface ResetUserStatus {
  type: authTypes.AUTH_RESET_USER_STATUS;
  payload: {
    userStatus: null;
  };
}

export interface UpdateUserPoints {
  type: authTypes.AUTH_UPDATE_USER_POINTS;
  payload: { userPoints: number };
}

export type AuthActionTypes =
  | IAuthorizeUser
  | IUserDeclined
  | IImpersonate
  | IUserLogout
  | SetInitialUserInfo
  | SetTokens
  | SetUserDataUpdate
  | SetNewsletterSubscription
  | ResetUserStatus
  | UpdateUserPoints;

/*-------------- AUTH ACTIONS --------------- */

export const getUserInfo = async (accessToken: string): Promise<IUser> => {
  const response = await fetch(`${BASE_URL}${apiPaths.CALL.USER_INFO}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return response.ok ? await response.json() : null;
};

export const login =
  ({
    email,
    password,
    redirect = true,
  }: {
    email: string;
    password: string;
    redirect: boolean;
  }) =>
  async (dispatch: Dispatch) => {
    try {
      const {
        data: { accessToken, refreshToken },
      } = await api.loginCall({
        dataPath: apiPaths.AUTH.LOGIN,
        data: {
          email,
          password,
        },
      });

      setCookieFromClient(ACCESS_TOKEN, accessToken, 1);
      setCookieFromClient(REFRESH_TOKEN, refreshToken);

      const user: IUser = await getUserInfo(accessToken);

      if (!user.magento) {
        user.magento = {
          orderRestrictions: {
            ...defaultOrderRestrictions,
          },
        } as ICustomer;
      }

      setCookieFromClient(UUID_USER, user.uuidUser);

      const userStatus: UserStatusEnum = getUserStatus(user);

      if (!userStatus) {
        removeCookieFromClient(ACCESS_TOKEN);
        removeCookieFromClient(REFRESH_TOKEN);

        await dispatch({
          type: authTypes.AUTH_USER_DECLINED,
          payload: {
            userStatus,
          },
        });
        return false;
      }
      await dispatch({
        type: authTypes.AUTH_USER_LOGGED,
        payload: {
          accessToken,
          refreshToken,
          user: {
            ...user,
          },
          userStatus,
        },
      });
      if (redirect) {
        redirectTo(INDEX);
      }
      return true;
    } catch (error) {
      console.group('error', error);
      throw error;
    }
  };

export const getUserInitialData =
  (
    accessToken: string,
    refreshToken: string,
    user: IUser,
    userStatus: UserStatusEnum
  ) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (dispatch: any, getState: () => IRootReducers) => {
    try {
      const [userPoints, userCommercialSubscriptionTokenStatus] =
        await Promise.all([
          getUserPoints(accessToken),
          isDEPlatform
            ? await getCommercialSubscriptionTokenStatus()
            : 'noToken',
        ]);

      if (getState().auth.accessToken) {
        await Promise.all([
          dispatch(fetchClientUserMenu(accessToken)),
          dispatch(fetchDisclaimers(accessToken)),
          dispatch(fetchProductsLimit()),
        ]);
        dispatch({
          type: authTypes.AUTH_USER_LOGGED,
          payload: {
            accessToken,
            refreshToken,
            user: {
              ...user,
              userMainData: buildUserMainData(user),
            },
            userPoints: userPoints || 0,
            userStatus,
            userCommercialSubscriptionTokenStatus,
          },
        });
        dispatch(
          _updateNewsletterSubscription(
            Boolean(Number(user.salesforce?.newsletterSubscription))
          )
        );
      }
    } catch (err) {
      logout();
      console.error(err);
    }
    return true;
  };

export const logout = (ctx?: ReduxPageContext) => async () => {
  if (ctx) {
    ctx.store.dispatch({
      type: appTypes.APP_FETCH_USER_HOME,
      payload: { userHome: null },
    });
    ctx.store.dispatch({
      type: appTypes.APP_FETCH_USER_MENU,
      payload: { userMenu: null },
    });
    ctx.store.dispatch({ type: authTypes.AUTH_LOGOUT, payload: {} });
    ctx.store.dispatch({ type: appTypes.APP_RESET, payload: {} });

    removeCookiesFromServer(ctx);
    redirectTo(INDEX, ctx);
  } else {
    Router.push(LOGOUT);
  }
};

export const impersonate = async (ctx: NextPageContext) => {
  let user = null;
  const accessToken = ctx.query.token;

  if (accessToken) {
    try {
      const res = await fetch(`${BASE_URL}${apiPaths.CALL.USER_INFO}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      user = await res.json();

      if (user) {
        const redirect = ctx.query?.redirect;
        const targetURL = redirect ? ctx.query?.targetURL : null;
        const isPreview = Object.keys(ctx.query).includes('redirect');
        const accessExpires = new Date();
        accessExpires.setDate(accessExpires.getDate() + 1);

        ctx.res.setHeader('Set-Cookie', [
          serialize(ACCESS_TOKEN, accessToken.toString(), {
            path: '/',
            expires: accessExpires,
            domain: BASE_COOKIE_DOMAIN,
          }),
          serialize(REFRESH_TOKEN, '', {
            path: '/',
            maxAge: -1,
            domain: BASE_COOKIE_DOMAIN,
          }),
        ]);
        const userPoints = await getUserPoints(accessToken.toString());
        await ctx.store.dispatch(
          _impersonate(
            accessToken.toString(),
            user,
            userPoints,
            targetURL,
            isPreview
          )
        );
        await fetchServerUserMenu(accessToken.toString(), ctx);
        await fetchServerDisclaimers(accessToken.toString(), ctx);
      }
    } catch (err) {
      return err.response;
    }
  }

  return [user, accessToken];
};

const _impersonate =
  (
    accessToken: string,
    user: IUser,
    userPoints: number,
    targetURL: string | string[],
    isPreview: boolean
  ) =>
  async (dispatch: Dispatch<IImpersonate>) => {
    const userStatus: UserStatusEnum = getUserStatus(user);
    dispatch({
      type: authTypes.AUTH_IMPERSONATE,
      payload: {
        accessToken,
        refreshToken: '',
        impersonate: true,
        impersonateTargetURL: targetURL,
        isPreview: isPreview,
        user: {
          ...user,
          userMainData: buildUserMainData(user),
        },
        userPoints: userPoints || 0,
        userStatus,
      },
    });
  };

export const _setInitialUserInfo =
  ({
    user,
    userPoints,
    accessToken,
    refreshToken,
  }: {
    user: IUser;
    userPoints: number;
    accessToken?: string;
    refreshToken?: string;
  }) =>
  (dispatch: Dispatch<SetInitialUserInfo>) => {
    const userStatus: UserStatusEnum = getUserStatus(user);

    if (accessToken && refreshToken)
      dispatch({
        type: authTypes.AUTH_SET_INITIAL_USER,
        payload: {
          accessToken,
          refreshToken,
          user: {
            ...user,
            userMainData: buildUserMainData(user),
          },
          userPoints: userPoints || 0,
          userStatus,
        },
      });
    else
      dispatch({
        type: authTypes.AUTH_SET_INITIAL_USER,
        payload: {
          user: {
            ...user,
            userMainData: buildUserMainData(user),
          },
          userPoints: userPoints || 0,
          userStatus,
        },
      });
  };

const _setTokens =
  (accessToken: string, refreshToken: string) =>
  (dispatch: Dispatch<SetTokens>) => {
    dispatch({
      type: authTypes.AUTH_SET_TOKENS,
      payload: {
        accessToken,
        refreshToken,
      },
    });
  };

export const _reAuthenticate = async (ctx: ReduxPageContext) => {
  const oldAccessToken = getCookie(ACCESS_TOKEN, ctx.req);
  const oldRefreshToken = getCookie(REFRESH_TOKEN, ctx.req);

  try {
    const url = `${BASE_URL}${apiPaths.AUTH.REFRESH}`;

    const response = await axios.post(
      url,
      {
        accessToken: oldAccessToken,
        refreshToken: oldRefreshToken,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization:
            oldAccessToken.indexOf('Bearer') < 0
              ? `Bearer ${oldAccessToken}`
              : oldAccessToken,
        },
      }
    );

    const { accessToken, refreshToken } = response.data;

    if (ctx.req) {
      setCookiesFromServer(ctx, accessToken, refreshToken);
    } else {
      setCookieFromClient(ACCESS_TOKEN, accessToken, 1);
      setCookieFromClient(REFRESH_TOKEN, refreshToken, 5);
    }

    _setTokens(accessToken, refreshToken)(ctx.store.dispatch);

    return { newAccessToken: accessToken };
  } catch (err) {
    logout(ctx);
  }
};

export const fetchUserData = async (
  ctx: NextPageContext,
  pathname: string = null
) => {
  let accessToken = null;
  let user = null;

  const enabledPaths = [
    ...publicPaths,
    ...getPublicMenus(ctx.store.getState().app.publicMenu),
  ];
  const isPublicRoute = enabledPaths.includes(ctx.pathname);

  if (ctx.req) {
    accessToken = ctx.store.getState().auth?.accessToken;
    //SERVER SIDE
    if (ctx.req.headers.cookie && !accessToken) {
      accessToken = getCookie(ACCESS_TOKEN, ctx.req);
      const refreshToken = getCookie(REFRESH_TOKEN, ctx.req);
      if (accessToken && refreshToken) {
        try {
          const res = await fetch(`${BASE_URL}${apiPaths.CALL.USER_INFO}`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          if (res.status === 200) {
            user = await res.json();

            if (getUserStatus(user) === UserStatusEnum.DENIED)
              await ctx.store.dispatch(logout(ctx));
            else
              await fetchUserInitialData(accessToken, refreshToken, ctx, user);
          } else if (res.status === 401) {
            const reAuthentication = await _reAuthenticate(ctx);

            if (!reAuthentication) {
              await ctx.store.dispatch(logout(ctx));
            } else {
              const refreshCall = await fetch(
                `${BASE_URL}${apiPaths.CALL.USER_INFO}`,
                {
                  method: 'GET',
                  headers: {
                    Authorization: `Bearer ${reAuthentication.newAccessToken}`,
                  },
                }
              );

              if (refreshCall.status === 200) {
                user = await refreshCall.json();

                if (getUserStatus(user) === UserStatusEnum.DENIED)
                  await ctx.store.dispatch(logout(ctx));
                else
                  await fetchUserInitialData(
                    accessToken,
                    refreshToken,
                    ctx,
                    user
                  );
              } else {
                await ctx.store.dispatch(logout(ctx));
              }
            }
          } else {
            await ctx.store.dispatch(logout(ctx));
          }
        } catch (err) {
          await ctx.store.dispatch(logout(ctx));
        }
      } else {
        if (!isPublicRoute) {
          redirectTo(`${LOGIN}?redirect=${pathname}`, ctx);
        }
      }
    }
    //CLIENT SIDE
  } else {
    user = ctx.store.getState().auth.user;
    accessToken = ctx.store.getState().auth.accessToken;
  }

  //TODO check is there ir user info? (BL)
  if (!accessToken && !isPublicRoute) {
    redirectTo(`${LOGIN}?redirect=${pathname.replace('?', '&')}`, ctx);
  }

  return [user, accessToken];
};

const getUserPoints = async (accessToken: string) => {
  const points = await fetch(
    `${BASE_URL}${apiPaths.MAGENTO.GET_CUSTOMER_POINTS}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return await points.json();
};

const getCommercialSubscriptionTokenStatus = async () => {
  const commercialSubscriptionTokenStatus = await api.getDataCall({
    dataPath: apiPaths.NEWSLETTER_SUBSCRIPTION.GET_COMMERCIAL_STATUS,
    callConfig: {},
  });
  return commercialSubscriptionTokenStatus.data;
};

const fetchUserInitialData = async (
  accessToken: string,
  refreshToken: string,
  ctx: NextPageContext,
  // eslint-disable-next-line
  user: any
) => {
  try {
    const userPoints = await getUserPoints(accessToken);
    await fetchServerUserMenu(accessToken, ctx);
    await fetchServerDisclaimers(accessToken, ctx);

    await ctx.store.dispatch(
      _setInitialUserInfo({
        user: {
          ...user,
          userMainData: buildUserMainData(user.data),
        },
        userPoints,
        accessToken,
        refreshToken,
      })
    );
  } catch (err) {
    console.error(err);
    ctx.store.dispatch(logout(ctx));
  }
};

export const resetUserStatus = () => (dispatch: Dispatch<ResetUserStatus>) => {
  dispatch({
    type: authTypes.AUTH_RESET_USER_STATUS,
    payload: { userStatus: null },
  });
};

export const _updateNewsletterSubscription =
  (newsletterSubscription: boolean) =>
  (dispatch: Dispatch<SetNewsletterSubscription>) => {
    dispatch({
      type: authTypes.AUTH_UPDATE_NEWSLETTER_SUBSCRIPTION,
      payload: {
        newsletterSubscription,
      },
    });
  };

export const updateSalesforceData =
  ({
    userName,
    userFirstname,
    userSurname,
    userMail,
    userPhone,
    userDocumentID,
    userMedicalCenter,
  }: {
    userName: string;
    userFirstname: string;
    userSurname: string;
    userMail: string;
    userPhone: string;
    userDocumentID?: string;
    userMedicalCenter?: string;
  }) =>
  (dispatch: Dispatch<SetUserDataUpdate>, getState: () => IRootReducers) => {
    const newUser: IUser = JSON.parse(JSON.stringify(getState().auth.user));

    if (newUser.salesforce) {
      newUser.salesforce.userName = userName;
      newUser.salesforce.userFirstname = userFirstname;
      newUser.salesforce.userSurname = userSurname;
      newUser.salesforce.userMail = userMail;
      newUser.salesforce.userPhone = userPhone;

      if (!isUSAPlatform) newUser.salesforce.userDocumentID = userDocumentID;
      if (!isUSAPlatform)
        newUser.salesforce.userMedicalCenter = userMedicalCenter;

      dispatch({
        type: authTypes.AUTH_UPDATE_USER_DATA,
        payload: {
          user: {
            ...newUser,
            userMainData: buildUserMainData(newUser),
          },
        },
      });
    }
  };

export const updateMagentoData =
  (magento: ICustomer) =>
  (dispatch: Dispatch<SetUserDataUpdate>, getState: () => IRootReducers) => {
    let user = { ...getState().auth.user };

    if (magento) user = { ...user, magento };

    dispatch({
      type: authTypes.AUTH_UPDATE_USER_DATA,
      payload: {
        user: {
          ...user,
          userMainData: buildUserMainData(user),
        },
      },
    });
  };

export const updateUserBirthday =
  (birthday: string) =>
  (dispatch: Dispatch<SetUserDataUpdate>, getState: () => IRootReducers) => {
    const user = { ...getState().auth.user };

    if (birthday) user.birthday = birthday;

    if (user.salesforce) {
      user.salesforce.userBirthday = birthday;
    }

    dispatch({
      type: authTypes.AUTH_UPDATE_USER_DATA,
      payload: {
        user: {
          ...user,
        },
      },
    });
  };

export const updateUserDocumentId =
  (documentId: string) =>
  (dispatch: Dispatch<SetUserDataUpdate>, getState: () => IRootReducers) => {
    const user = { ...getState().auth.user };

    if (user.salesforce) {
      user.salesforce.userDocumentID = documentId;
      user.salesforce.userDocumentIDHasBeenModified = true;
    }

    dispatch({
      type: authTypes.AUTH_UPDATE_USER_DATA,
      payload: {
        user: {
          ...user,
        },
      },
    });
  };

export const updateUserDocumentIdHasBeenModified =
  (state: boolean) =>
  (dispatch: Dispatch<SetUserDataUpdate>, getState: () => IRootReducers) => {
    const user = { ...getState().auth.user };

    if (user.salesforce) {
      user.salesforce.userDocumentIDHasBeenModified = state;
    }

    dispatch({
      type: authTypes.AUTH_UPDATE_USER_DATA,
      payload: {
        user: {
          ...user,
        },
      },
    });
  };

export const getUserClosedCommercialModalDate =
  () =>
  async (
    dispatch: Dispatch<SetUserDataUpdate>,
    getState: () => IRootReducers
  ) => {
    const user = { ...getState().auth.user };

    const result = await api.getDataCall({
      dataPath: apiPaths.NEWSLETTER_SUBSCRIPTION.COMMERCIAL_MODAL_CLOSED,
      callConfig: {},
    });

    user.salesforce.mailSubscription.commercialModalClosedDate = result.data;

    dispatch({
      type: authTypes.AUTH_UPDATE_USER_DATA,
      payload: {
        user: {
          ...user,
        },
      },
    });
  };

export const updateUserInfo =
  () => async (dispatch: Dispatch<SetUserDataUpdate>) => {
    await api
      .getUserInfo({
        dataPath: apiPaths.CALL.USER_INFO,
      })
      .then((res) => {
        dispatch({
          type: authTypes.AUTH_UPDATE_USER_DATA,
          payload: {
            user: {
              ...res.data,
              userMainData: buildUserMainData(res.data),
            },
          },
        });
      });
  };

export const updateUserFields =
  (field: Partial<IUser>) =>
  (dispatch: Dispatch<SetUserDataUpdate>, getState: () => IRootReducers) => {
    const user = { ...getState().auth.user };

    dispatch({
      type: authTypes.AUTH_UPDATE_USER_DATA,
      payload: {
        user: {
          ...user,
          ...field,
        },
      },
    });
  };

export const updateUserExpirationPoints =
  (expirationPoints: IExpirationPointsInfo) =>
  (dispatch: Dispatch<SetUserDataUpdate>, getState: () => IRootReducers) => {
    let user = { ...getState().auth.user };

    user = { ...user, expirationPoints: expirationPoints };

    dispatch({
      type: authTypes.AUTH_UPDATE_USER_DATA,
      payload: {
        user: {
          ...user,
          userMainData: buildUserMainData(user),
        },
      },
    });
  };

export const updateUserData =
  (user: IUser) => (dispatch: Dispatch<SetUserDataUpdate>) => {
    dispatch({
      type: authTypes.AUTH_UPDATE_USER_DATA,
      payload: {
        user: {
          ...user,
          userMainData: buildUserMainData(user),
        },
      },
    });
  };

export const updateUserPoints =
  (userPoints: number) => (dispatch: Dispatch<UpdateUserPoints>) => {
    dispatch({
      type: authTypes.AUTH_UPDATE_USER_POINTS,
      payload: { userPoints: userPoints || 0 },
    });
  };

export const updateMailSubscriptionStatus =
  (newStatus: boolean, onlyGet?: boolean) =>
  async (dispatch: Dispatch<SetUserDataUpdate>) => {
    if (!onlyGet)
      await api.postDataCall({
        dataPath: apiPaths.NEWSLETTER_SUBSCRIPTION.COMMERCIAL,
        callConfig: {},
        data: { commercialSubscriptionStatus: newStatus },
      });

    await api
      .getUserInfo({
        dataPath: apiPaths.CALL.USER_INFO,
      })
      .then((res) => {
        dispatch({
          type: authTypes.AUTH_UPDATE_USER_DATA,
          payload: {
            user: {
              ...res.data,
              userMainData: buildUserMainData(res.data),
            },
            userCommercialSubscriptionTokenStatus: newStatus
              ? 'valid'
              : 'noToken',
          },
        });
      });
  };

const buildUserMainData = (user: IUser): IUserMainData => {
  if (!user) {
    return {
      name: 'error-getting-user_data',
      lastname: 'error-getting-user_data',
    };
  }

  return {
    name: isUSAPlatform
      ? user.salesforce.userFirstname
      : user.magento?.firstname ?? user.salesforce.userFirstname,
    lastname: isUSAPlatform
      ? user.salesforce.userSurname
      : user.magento?.lastname ?? user.salesforce.userSurname,
  };
};
