import { Button, Col, InputNumber, Row } from 'antd';
import { DateTime } from 'luxon';
import { config as configApp } from 'config/config';
import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { deleteDataCall, postDataCall, putDataCall } from 'services/api';
import apiPaths from 'services/apiPaths';
import {
  ShowDetailsModal,
  ViewMoreButton,
} from 'src/components/challenge/ChallengeDetailsBox';
import { IResourceList } from 'src/components/challenge/ChallengeInterfaces';
import ChallengeResourceWrapper, {
  ChallengeResourceWrapper_Blocked,
} from 'src/components/challenge/ChallengeResourceWrapper';
import CustomModal, { loadingSpinner__small } from 'src/components/CustomModal';
import { getResourceStatusMessage } from 'src/components/challenge/ChallengeUserUploadMedia';
import { UploadFiles } from 'src/components/challenge/UploadFiles';
import { IRootReducers } from 'src/reducers';
import { S3BucketPathEnum, SellOutStatusEnum } from 'src/shared/enums';
import { IChallengeResourceRender, ISellOut, IUser } from 'src/shared/models';
import { getShortDataFormat } from '../../../utils';
import { getTimeZone } from 'utils/timeUtils';
import _ from 'lodash';

const SellOutModals = ({
  showCompleteReportModal,
  setShowCompleteReportModal,
  showSignedInModal,
  bodyEndDate,
  setShowSignedInModal,
}) => {
  return (
    <>
      <CustomModal
        visible={showCompleteReportModal}
        content={
          <div className="sell-out__report-complete-modal">
            <p className="section-title">
              <FormattedMessage id="sell-out.ok-modal.title" />
            </p>
            <p className="regular-body">
              <FormattedMessage id="sell-out.ok-modal.text" />
            </p>
          </div>
        }
        centered={true}
        showIcon={true}
        footer={false}
        onCancel={() => setShowCompleteReportModal(false)}
      />
      <CustomModal
        visible={showSignedInModal}
        centered
        content={
          <div className="sell-out__report-complete-modal">
            <p className="section-title">
              <FormattedMessage id="sell-out.label-messages.succesfully-registered" />
            </p>
            <p className="regular-body">
              <FormattedMessage
                id="sell-out.label-messages.you-can-report-your-sales-from-day-{day}"
                values={{
                  day: DateTime.fromJSDate(new Date(bodyEndDate))
                    .setZone(getTimeZone())
                    .toFormat(getShortDataFormat()),
                }}
              />
            </p>
          </div>
        }
        showIcon={true}
        footer={false}
        onCancel={() => setShowSignedInModal(false)}
      />
    </>
  );
};

const LabelToReport = ({ showLabelToReport, bodyEndDate }) => {
  if (!showLabelToReport) return <React.Fragment />;

  return (
    <b className="regular-body sell-out__you-can-report">
      <FormattedMessage
        id="sell-out.label-messages.you-can-report-your-sales-from-day-{day}"
        values={{
          day: DateTime.fromJSDate(new Date(bodyEndDate))
            .setZone(getTimeZone())
            .toFormat(getShortDataFormat()),
        }}
      />
    </b>
  );
};

const LabelToUploadMedia = ({
  showLabelToUploadMedia,
  hasUpload,
  reportedQuantityTitle,
  quantity,
  setQuantity,
  mediaData,
  handleDeleteFile,
  minQuantity,
  userUploadconfig,
  accessToken,
  handleUpdateResource,
}) => {
  if (!showLabelToUploadMedia) return <React.Fragment />;

  const mediaIsUploaded = hasUpload && mediaData.isUploaded;

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const _quantity = parseFloat(event.target.value) || 0;
    setQuantity(_quantity);
  };

  const handleOnChange = (value: string) => {
    const _quantity = parseFloat(value) || 0;
    setQuantity(_quantity);
  };

  return (
    <>
      {!hasUpload && (
        <>
          <div className="sell-out__introduce-number-block">
            <Row
              className="regular-body column-units"
              justify="center"
              align="middle"
            >
              <Col xs={12} md={10}>
                <p className="regular-body sell-out__introduce-number-block-title">
                  {reportedQuantityTitle}
                </p>
              </Col>
              <Col xs={12} md={4}>
                <Row justify="center" align="middle">
                  <InputNumber
                    className="input-number"
                    value={quantity}
                    onBlur={handleOnBlur}
                    onChange={handleOnChange}
                    min={0}
                  />
                </Row>
              </Col>
            </Row>
          </div>
          <div className="container-repeat-quiz sell-out__report-blocked">
            <FormattedMessage id="sell-out.report-now.blocked" />
          </div>
          <div className="sell-out__report-blocked">
            <FormattedMessage id="sell-out.label-messages.upload-report-proof" />
          </div>
        </>
      )}
      {mediaIsUploaded &&
        getResourceStatusMessage(mediaData.statusResource, true)}
      <div className="sell-out__report-now-block">
        <Row className="container-file-data" justify="center">
          {mediaIsUploaded ? (
            <Row justify="center" className="file-data__container">
              <a className="file-name" href={mediaData.url} target="_blank">
                {mediaData.fileName}
              </a>
              <span className="file-size">({mediaData.size})</span>
              {mediaData.statusResource !== SellOutStatusEnum.VALIDATED && (
                <a
                  className="icon icon--delete file-icon"
                  onClick={handleDeleteFile}
                />
              )}
            </Row>
          ) : (
            <UploadFiles
              btnDisabled={quantity === 0 || quantity < minQuantity}
              userUploadconfig={userUploadconfig}
              accessToken={accessToken}
              updateResource={handleUpdateResource}
              beforeUpload={_.noop}
              afterError={_.noop}
              s3Bucket={S3BucketPathEnum.SELL_OUT_REPORTS}
            />
          )}
        </Row>
      </div>
    </>
  );
};

interface OwnProps extends IChallengeResourceRender {
  config: ISellOut;
  resource: IResourceList;
  user: IUser;
  idChallenge: number;
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps;

const ChallengeSellOut: FC<Props> = ({
  config,
  accessToken,
  updateChallenge,
  updateUserPoints,
  resource,
  idChallenge,
}) => {
  const { formatNumber } = useIntl();
  const [showCompleteReportModal, setShowCompleteReportModal] = useState(false);
  const [showSignedInModal, setShowSignedInModal] = useState(false);
  const [showMoreDetailsModal, setShowMoreDetailsModal] = useState(false);
  const [shortDescription, setShortDescription] = useState(null);
  const [showSeeMore, setShowSeeMore] = useState(false);
  const [quantity, setQuantity] = useState<number>(0);
  const [loadingSignIn, setLoadingSignIn] = useState<boolean>(false);

  if (!config) return <React.Fragment />;
  const {
    bodyDescription,
    bodyEndDate,
    bodyImage,
    bodyStartDate,
    bodyTitle,
    descriptionLarge,
    hasUpload,
    isManager,
    isSignedIn,
    mediaData,
    minQuantity,
    noManagerLabel,
    points,
    reportedQuantityTitle,
    showLabelToReport,
    showLabelToUploadMedia,
    title,
    hasExpired,
  } = config;

  const userUploadconfig = {
    ...config,
    buttonTitle: <FormattedMessage id="sell-out.report-now" />,
  };

  const handleSignIn = async () => {
    if (isSignedIn) return;
    setLoadingSignIn(true);
    // Change sellOutStatus value to SellOutStatusEnum.REGISTERED
    const response = await postDataCall({
      dataPath: apiPaths.CHALLENGES.USER_RESOURCE_SELL_OUT,
      data: {
        idResource: resource.idResource,
        idChallenge,
      },
      callConfig: {},
    });

    setShowSignedInModal(true);
    updateChallenge(response.data.challenge);
    updateUserPoints(response.data.score);
    setLoadingSignIn(false);
  };

  const handleUpdateResource = async (
    value: string,
    fileName: string,
    size: string
  ) => {
    const response = await putDataCall({
      dataPath: apiPaths.CHALLENGES.USER_RESOURCE_SELL_OUT,
      data: {
        userResource: {
          idUserResource: mediaData.idUserResource,
          idResource: resource.idResource,
          status: true,
          value,
          textMachine: `${fileName}|${size}`,
          statusResource: SellOutStatusEnum.PENDING,
          reportedQuantities: quantity,
        },
        idChallenge,
      },
      callConfig: {},
    });

    setShowCompleteReportModal(true);
    updateChallenge(response.data);
  };

  const handleDeleteFile = async () => {
    const response = await deleteDataCall({
      dataPath: apiPaths.CHALLENGES.USER_RESOURCE_SELL_OUT,
      id: mediaData.idUserResource,
      callConfig: {},
    });

    updateChallenge(response.data);
  };

  const ButtonOrLabel = () => {
    let _button: JSX.Element;

    switch (true) {
      case !isManager:
        _button = <>{noManagerLabel}</>;
        break;
      case isSignedIn:
        _button = (
          <div className="challengeResourceWrapper__label challengeResourceWrapper__label--completed">
            <FormattedMessage id="sell-out.label-messages.succesfully-registered" />
          </div>
        );
        break;

      case isManager:
        _button = (
          <Button
            disabled={hasExpired}
            className={`btn-primary ${
              hasExpired
                ? 'btn-ghost btn-ghost--black btn-ghost--black--disabled'
                : ''
            }`}
            onClick={handleSignIn}
          >
            <FormattedMessage id="app.sign-up" />
            {loadingSignIn && loadingSpinner__small}
          </Button>
        );
        break;

      default:
        _button = (
          <div className="container-repeat-quiz">
            <FormattedMessage id="page.challenge.sell-out.your-manager-entry-status-not-registered" />
          </div>
        );
        break;
    }

    return _button;
  };

  const ExpiredLabel = () => {
    if (!hasExpired) return <React.Fragment />;

    return (
      <Row
        className={'container-repeat-quiz expired-challenge'}
        justify="center"
        align="middle"
      >
        <FormattedMessage id="page.challenge.challenge-has-expired-upload" />
      </Row>
    );
  };

  useEffect(() => {
    if (!bodyDescription.trim()) return;

    if (bodyDescription.length >= 70) {
      setShowSeeMore(true);
      setShortDescription(bodyDescription.substr(0, 70));
    }
  }, [bodyDescription]);

  const component = (
    <Row justify="center">
      <Col xs={24} md={20} className="sell-out__large-description">
        {/* //TODO use ChallengeDetailsBox */}
        <Row className="sell-out__details">
          <Col xs={24} md={14} lg={12}>
            <ChallengeResourceWrapper_Blocked />
            <img className="sell-out__details-image" src={bodyImage} />
          </Col>
          <Col xs={18} md={10} lg={12} className="sell-out__details-col">
            <h4 className='h4'>{bodyTitle}</h4>
            <p className="regular-body">
              {shortDescription ? shortDescription : bodyDescription}
            </p>
            {showSeeMore && (
              <ViewMoreButton setShowModal={setShowMoreDetailsModal} />
            )}
            <div className="sell-out__details-info">
              <i className="icon icon--calendar challenge-detail-box-icon" />
              <FormattedMessage
                id="page.challenge-card.{startDate}-{endDate}"
                values={{
                  startDate: DateTime.fromJSDate(new Date(bodyStartDate))
                    .setZone(getTimeZone())
                    .toFormat(getShortDataFormat()),
                  endDate: DateTime.fromJSDate(new Date(bodyEndDate))
                    .setZone(getTimeZone())
                    .toFormat(getShortDataFormat()),
                }}
              />
            </div>
            <div className="sell-out__details-info">
              {points >= configApp.APP.SHOW_POINTS_LIMIT && (
                <>
                  <i className="icon icon--puntos challenge-detail-box-icon" />
                  <FormattedMessage
                    id="page.challenge-resource.earn-up-to-{points}-points"
                    values={{ points: formatNumber(points) }}
                  />
                </>
              )}
            </div>
            <div className="sell-out__button-label-container">
              {!hasUpload && <ButtonOrLabel />}
            </div>
          </Col>
        </Row>
        <ShowDetailsModal
          description={bodyDescription}
          title={bodyTitle}
          setShowModal={setShowMoreDetailsModal}
          showModal={showMoreDetailsModal}
        />
        <div
          className="sell-out__description-paragraph"
          dangerouslySetInnerHTML={{ __html: descriptionLarge }}
        />
        {hasExpired ? (
          <ExpiredLabel />
        ) : (
          <>
            <LabelToReport {...{ bodyEndDate, showLabelToReport }} />
            <LabelToUploadMedia
              {...{
                showLabelToUploadMedia,
                hasUpload,
                reportedQuantityTitle,
                quantity,
                setQuantity,
                accessToken,
                handleDeleteFile,
                handleUpdateResource,
                mediaData,
                minQuantity,
                userUploadconfig,
              }}
            />
          </>
        )}
      </Col>
      <SellOutModals
        {...{
          showSignedInModal,
          bodyEndDate,
          setShowCompleteReportModal,
          setShowSignedInModal,
          showCompleteReportModal,
        }}
      />
    </Row>
  );

  return (
    <ChallengeResourceWrapper
      children={component}
      description={config.description}
      icon={config.icon}
      styleClass="challengeDetail--sell-out"
      title={title}
    />
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    accessToken: state.auth.accessToken,
  };
};

export default connect(mapStateToProps)(ChallengeSellOut);
