import { Col, Row } from 'antd';
import { config as appConfig } from 'config/config';
import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getDataCall } from 'services/api';
import apiPaths from 'services/apiPaths';
import { ChallengeDetailsBox } from 'src/components/challenge/ChallengeDetailsBox';
import ChalllengeResourceWrapper from 'src/components/challenge/ChallengeResourceWrapper';
import Quiz from 'src/components/quiz/Quiz';
import { IRootReducers } from 'src/reducers';
import { ChallengeTypeEnum } from 'src/shared/enums';
import { IChallengeQuiz, IChallengeResourceRender } from 'src/shared/models';
import { isUSAPlatform, setQuizBehavior } from '../../../utils';

interface OwnProps extends IChallengeResourceRender {
  config: IChallengeQuiz;
  isTraining: boolean;
  icon?: string;
}

type Props = OwnProps & ReturnType<typeof mapStateToProps>;

const ChallengeQuiz: FC<Props> = ({
  challengeType,
  config,
  isTraining,
  resourceBehavior,
  updateUserPoints,
  updateChallenge,
}): JSX.Element => {
  const [quizConfig, setQuizConfig] = useState<IChallengeQuiz>(
    isTraining ? config : setQuizBehavior(config, resourceBehavior)
  );

  const { description, points, title, showPresentation, allowRetry, icon } =
    quizConfig;
  const [showQuiz, setShowQuiz] = useState<boolean>(!showPresentation);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { formatMessage } = useIntl();

  const isEventQuiz = showPresentation;

  const handleGetStatistics = async (idChallenge: number) => {
    const { GET_QUIZ_STATISTICS } = apiPaths.CHALLENGES;

    const request = `${GET_QUIZ_STATISTICS}/${idChallenge}`;

    try {
      const response: {
        // Data has others properties that are not required.
        data: {
          userAnswers: [
            {
              resourceM2MList: [
                {
                  idResource: {
                    idResource: number;
                    indResource: number;
                  };
                }
              ];
            }
          ];
        };
      } = await getDataCall({
        dataPath: request,
        callConfig: {},
      });

      const _config = isTraining
        ? { ...config }
        : { ...setQuizBehavior(config, resourceBehavior) };

      const _map: {
        [idQuestion: number]: {
          index: number;
          answers: {
            [idAnswer: number]: number;
          };
        };
      } = {};

      // Build questions maps
      _config.questions.forEach(
        ({ idResource: idQuestion, answers }, questionIndex) => {
          _map[idQuestion] = {
            index: questionIndex,
            answers: {},
          };
          answers.forEach((answer, answerIndex) => {
            const { idResource: idAnswer } = answer;
            _map[idQuestion].answers[idAnswer] = answerIndex;
          });
        }
      );

      // Set users responses
      response.data.userAnswers.forEach((userAnswers) => {
        ++_config.totalResponses;
        userAnswers.resourceM2MList.forEach((userResponse) => {
          const { idResource: idAnswer, indResource: idQuestion } =
            userResponse.idResource;

          const questionIndex = _map[idQuestion].index;
          const answerIndex = _map[idQuestion].answers[idAnswer];

          ++_config.questions[questionIndex].answers[answerIndex].userResponses;
        });
      });

      setQuizConfig(_config);
    } catch (error) {
      console.error(error);
    }
  };

  const handleShowQuiz = () => setShowQuiz(isEventQuiz ? false : allowRetry);

  useEffect(() => {
    const { idChallenge } = quizConfig;
    setIsLoading(true);
    handleGetStatistics(idChallenge).then(() => {
      setIsLoading(false);
    });
  }, [config]);

  const quizComponent = (): JSX.Element =>
    showQuiz && (
      <Quiz
        config={quizConfig}
        handleShowQuiz={handleShowQuiz}
        {...{ updateUserPoints, updateChallenge }}
      />
    );

  const isChallengeTraining = challengeType === ChallengeTypeEnum.FORMATION;
  // !DEPRECATED: Remove it
  const is_hotfix_melatonin =
    isUSAPlatform && challengeType === ChallengeTypeEnum.CAMPAIGN;

  return quizConfig.shouldRender ? (
    <ChalllengeResourceWrapper
      styleClass="challengeDetail--Quiz"
      title={
        is_hotfix_melatonin
          ? 'Quiz'
          : formatMessage({
              id: 'page.challenge.quiz.title',
            })
      }
      description={
        is_hotfix_melatonin
          ? formatMessage({
              id: 'page.challenge.quiz.title',
            })
          : null
      }
      points={
        is_hotfix_melatonin
          ? null
          : !isChallengeTraining &&
            points > 0 &&
            formatMessage(
              { id: 'page.challenge.plus-sign-{points}-points' },
              { points: points }
            )
      }
      icon={!isChallengeTraining && icon}
      children={
        <Row
          justify="center"
          className={`challengeDetail--quiz ${
            isChallengeTraining ? 'challengeDetail--campaign-quiz' : 'old-quiz'
          }`}
          id="challengeDetail--quiz"
        >
          <Col span={24}>
            {showPresentation && (
              <ChallengeDetailsBox
                allowRetry={allowRetry}
                completed={quizConfig.isQuizDone}
                description={description.trim()}
                disabled={quizConfig.disabled}
                image={
                  quizConfig.urlImage ??
                  `${appConfig.APP.BASE_URL}/delegate_back.svg`
                }
                loading={isLoading}
                points={points}
                setShowComponent={setShowQuiz}
                showQuiz={showQuiz}
                title={title}
              />
            )}
            {quizComponent()}
          </Col>
        </Row>
      }
    />
  ) : null;
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    challengeType: state.app.challenge?.challengeType,
  };
};

export default connect(mapStateToProps)(ChallengeQuiz);
