import { Col, Row } from 'antd';
import { useRouter } from 'next/router';
import { config as configApp } from 'config/config';
import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as api from 'services/api';
import apiPaths from 'services/apiPaths';
import { UpdateUserPoints } from 'src/actions/authActions';
import { IRootReducers } from 'src/reducers';
import { IChallenge, IChallengeQuiz } from 'src/shared/models';
import {
  checkIfMenuIsVisible,
  customFormatMessage,
  getFirstUncompleteSurveyId,
  getParentChallengeId,
  hasCompletedSurvey,
  hasSurvey,
  scrollDown,
  UrlButton,
} from '../../../utils';
import Question from './Question';
import {
  YuButton,
  YuFlex,
  YuIcon,
  YuModal,
  YuSpacer,
  YuSpinner,
} from '@isdin/yuma-react-web-pin';
import ConditionalRenderer from '../ConditionalRenderer';

// TODO: Encapsulate into interface "IBasicResourceProps"
interface IProps {
  config: IChallengeQuiz;
  handleShowQuiz: React.Dispatch<React.SetStateAction<boolean>>;
  updateChallenge: React.Dispatch<React.SetStateAction<IChallenge>>;
  updateUserPoints: (
    userPoints: number
  ) => (dispatch: Dispatch<UpdateUserPoints>) => void;
}

const Quiz: FC<IProps & ReturnType<typeof mapStateToProps>> = ({
  config,
  handleShowQuiz,
  updateChallenge,
  updateUserPoints,
  challengeResources,
  userMenus,
}): JSX.Element => {
  const {
    idResource,
    questions,
    showStatistics,
    isQuizDone,
    completationTitle,
    allowContinue,
  } = config;
  const [savedAnswers, setSavedAnswers] = useState<number[]>([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState(null);
  const [pointsEarned, setPointsEarned] = useState(0);
  const [modalQuizDone, setModalQuizDone] = useState(isQuizDone);
  const [parentChallengeType, setParentChallengeId] = useState<number>();
  const { query } = useRouter();
  const { formatNumber } = useIntl();

  const showEarnedPoints =
    !modalQuizDone &&
    pointsEarned !== 0 &&
    pointsEarned !== null &&
    pointsEarned >= configApp.APP.SHOW_POINTS_LIMIT;

  const handleSavedAnswers = (idResourceAnswer: number) => {
    setSavedAnswers((prev) => [...prev, idResourceAnswer]);
  };

  const completedChallenge = () => {
    if (!challengeResources) return true;
    return challengeResources.every((resource) => resource.config.isCompleted);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    handleShowQuiz(false);
    setSavedAnswers([]);
    setError(null);
    setPointsEarned(0);
    setModalQuizDone(true);
  };

  const submitQuiz = async (idResourceAnswer?: number[]) => {
    try {
      setIsModalVisible(true);
      setLoading(true);
      const response = await api.putDataCallById({
        dataPath: apiPaths.CHALLENGES.COMPLETE_TRAINING,
        id: idResource,
        data: { resourceList: [...savedAnswers, ...idResourceAnswer] },
      });

      const { score, challenge, trainingScore } = response.data;

      if (score && typeof score === 'number') updateUserPoints(score);
      if (trainingScore) setPointsEarned(Number(trainingScore));

      updateChallenge(challenge);
    } catch (err) {
      setError(err);
      console.error(err);
    } finally {
      setSavedAnswers([]);
      setLoading(false);
    }
  };

  const goToUrlButton = () => {
    const urlButtonHandler = new UrlButton(
      'quiz',
      checkIfMenuIsVisible(userMenus),
      query,
      parentChallengeType,
      completedChallenge(),
      closeModal()
    );
    const scrollToId = getFirstUncompleteSurveyId(challengeResources);
    if (scrollToId) scrollDown(scrollToId);

    urlButtonHandler.handleUrlButton();
  };

  const getMessageButton = (): string => {
    if (!completedChallenge())
      return 'page.challenge.quiz.continue-with-survey';
    if (query?.idParentChallenge)
      return 'page.challenge.quiz.continue-with-campaign';
    else return 'page.challenge.quiz.find-more-experiences';
  };

  const getMessageModal = () => {
    if (completedChallenge()) return customFormatMessage(completationTitle);
    else
      return (
        <FormattedMessage id="page.challenge.congratulations-training-continue" />
      );
  };

  const hasIdParentChallenge = Boolean(query?.idParentChallenge);
  const isChallengeCompleted = completedChallenge();
  const hasIncompleteSurvey =
    hasSurvey(challengeResources) && !hasCompletedSurvey(challengeResources);
  const isMenuVisible = checkIfMenuIsVisible(userMenus);

  const showCTA = () => {
    if (error) return false;
    if (hasIdParentChallenge && isChallengeCompleted) return true;
    if (!isChallengeCompleted && hasIncompleteSurvey) return true;
    if (isChallengeCompleted && !isMenuVisible) return false;
    if (isChallengeCompleted && isMenuVisible) return true;
  };

  const errorIcon = 'CrossCircle';
  const noErrorIcon = 'CheckmarkCircle';
  const redColor = '#e00000';
  const greenColor = '#218500';

  const modalContent = (
    <YuFlex flexDirection="column" alignItems="center">
      <YuFlex justify="center" gap={'M'}>
        <YuIcon
          name={error ? errorIcon : noErrorIcon}
          size="XL"
          color={error ? redColor : greenColor}
        ></YuIcon>
      </YuFlex>
      <YuSpacer size="S" />

      <span className="finalize-title">{getMessageModal()}</span>

      {showEarnedPoints && (
        <FormattedMessage
          id="page.challenge.quiz.you-win-{points}-puntos"
          values={{
            points: formatNumber(Math.max(pointsEarned, 0)),
          }}
        />
      )}
      <ConditionalRenderer
        condition={showCTA()}
        trueComponent={
          <>
            <YuSpacer size="S" />
            <YuButton
              onClick={() => goToUrlButton()}
              children={customFormatMessage(getMessageButton())}
            />
          </>
        }
      />
      <YuSpacer size="S" />
    </YuFlex>
  );

  const modalResult = (): JSX.Element => {
    return (
      <YuModal
        behaviour={{
          close: { onClickOutArea: false },
        }}
        isOpen={isModalVisible}
        fullScreen={false}
        className="quiz-modal-finalize"
        size="M"
        closable={!loading}
        onClose={closeModal}
      >
        <YuFlex justify="center">
          {loading ? (
            <YuFlex justify="center" align="center" flexDirection="column">
              <YuSpacer size="XXL" />
              <YuSpinner size="XL" />
              <YuSpacer size="XXL" />
            </YuFlex>
          ) : (
            modalContent
          )}
        </YuFlex>
      </YuModal>
    );
  };

  const fetchData = async () => {
    setParentChallengeId(await getParentChallengeId());
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Row className="quiz-container" justify="center" align="middle">
      <Col xs={24} lg={20}>
        {questions.length && (
          <Question
            questions={questions}
            totalResponses={config.totalResponses}
            showStatistics={showStatistics}
            saveAnswer={handleSavedAnswers}
            handleSubmit={submitQuiz}
            allowContinue={allowContinue}
          />
        )}
      </Col>
      {modalResult()}
    </Row>
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    challengeResources: state.app.challenge?.challengeResources,
    userMenus: state.app.userMenu,
  };
};
export default connect(mapStateToProps)(Quiz);
