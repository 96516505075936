import { NextPage } from 'next';
import { SkeletonButton } from '../Button.skeleton';
import { SkeletonFilters } from '../Filters.skeleton';
import SkeletonSubHeaderTitle from '../SubHeaderTitle.skeleton';
import SkeletonCampaignCards from './CampaignCards.skeleton';

const SkeletonCampaign: NextPage = (): JSX.Element => {
  return (
    <>
      <SkeletonSubHeaderTitle />
      <div className="campaign-skeleton">
        <div className="campaign-skeleton-filters">
          <SkeletonFilters />
        </div>
        <SkeletonCampaignCards />
        <div className="campaign-skeleton-button">
          <SkeletonButton />
        </div>
      </div>
    </>
  );
};

export default SkeletonCampaign;
