import { Button, Col, notification, Row } from 'antd';
import React, { FC, useState } from 'react';
import { FormattedNumber, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { postDataCall } from 'services/api';
import apiPaths from 'services/apiPaths';
import { IResourceList } from 'src/components/challenge/ChallengeInterfaces';
import ChallengeResourceWrapper from 'src/components/challenge/ChallengeResourceWrapper';
import CustomModal, { loadingSpinner__small } from 'src/components/CustomModal';
import Product from 'src/components/shoppingCart/Product';
import { CustomCloseIcon } from 'src/components/shoppingCart/ShoppingCart';
import { IRootReducers } from 'src/reducers';
import { IChallengeResourceRender, ISellIn, IUser } from 'src/shared/models';
import { getCurrencyFormat, isManager } from '../../../utils';

interface OwnProps extends IChallengeResourceRender {
  config: ISellIn;
  resource: IResourceList;
  user: IUser;
  idChallenge: number;
}

type Props = OwnProps & ReturnType<typeof mapStateToProps>;

const ChallengeSellIn: FC<Props> = ({
  config,
  userStatus,
  updateChallenge,
  updateUserPoints,
}) => {
  const {
    title,
    description,
    conditions,
    icon,
    ranges,
    products,
    minItemsSellIn,
    idResource,
    points,
    isCompleted,
    isOrderPerformed,
  } = config;
  const [loading, setLoading] = useState(false);
  const [productsSellIn, setProductsSellIn] = useState(products);
  const [showConditionsModal, setShowConditionsModal] = useState(false);
  const [showCompleteReportModal, setShowCompleteReportModal] = useState(false);
  const { formatMessage, formatNumber } = useIntl();

  const handleMakeSellInOrder = async () => {
    const productsSelected = productsSellIn
      .filter(({ quantity }) => quantity > 0)
      .map(({ quantity, sku: idProduct }) => {
        return { quantity, idProduct };
      });

    setLoading(true);
    try {
      const sellInOrder = await postDataCall({
        dataPath: isOrderPerformed
          ? apiPaths.CHALLENGES.USER_RESOURCE_SELL_IN_REPEAT_ORDER
          : apiPaths.CHALLENGES.USER_RESOURCE_SELL_IN,
        data: { idResource, products: productsSelected },
        callConfig: {},
      });

      setShowCompleteReportModal(true);
      updateUserPoints(sellInOrder?.data.score);
      updateChallenge(sellInOrder?.data.challenge);
    } catch (error) {
      if (error.response) {
        notification.error({
          message: formatMessage({ id: 'sell-in.request-order.error' }),
        });
      }
      console.error(error);
    }
    setLoading(false);
  };

  const conditionsComponent = (
    <Row
      justify="center"
      className="sell-in__header"
      onClick={() => setShowConditionsModal(true)}
    >
      {formatMessage({ id: 'shopping-cart.conditions-label' })}
    </Row>
  );

  const rangesImagesComponent = (
    <Row className="sell-in__ranges" justify="center">
      {ranges.map((range) => (
        <Col xs={24} md={8}>
          <img src={range.imageRanges} className="sell-in__range-images" />
        </Col>
      ))}
    </Row>
  );

  const handleChangeQuantity = (idResource: number, newQuantity: number) => {
    const productsChanged = productsSellIn.map((product) => {
      if (
        product.idResource === idResource &&
        product.minItems <= newQuantity &&
        product.maxItems >= newQuantity
      ) {
        product.quantity = newQuantity;
      }
      return product;
    });
    setProductsSellIn(productsChanged);
  };

  const productsComponent = (
    <Row className="sell-in__products" justify="center">
      {productsSellIn.map((product) => (
        <Col xs={24} md={8} className="sell-in__product">
          <Product
            product={product}
            handleChangeQuantity={handleChangeQuantity}
            config={{ ...config, isCompleted: false }}
            withBorderInput={true}
            showQuantitySelector={isManager(userStatus)}
          />
        </Col>
      ))}
    </Row>
  );

  let totalPieces = 0;
  productsSellIn.map((product) => (totalPieces += product.quantity));

  let totalPrice = 0;
  productsSellIn.map(
    (product) => (totalPrice += product.quantity * product.price)
  );

  const resumeComponent = (
    <Row className="sell-in__resume">
      <p className="small-body">
        <b>{formatMessage({ id: 'page.request-order.total-units' })}:</b>
        <b className="not-uppercase">
          {formatMessage(
            { id: 'page.request-order.{units}-uds' },
            { units: totalPieces }
          )}
        </b>
      </p>
      <p className="small-body">
        <b>{formatMessage({ id: 'page.request-order.total-order' })}:</b>
        <b>
          <FormattedNumber
            value={totalPrice}
            style="currency"
            currency={getCurrencyFormat()}
          />
        </b>
      </p>
    </Row>
  );

  const footerComponent = (
    <Row className="sell-in__footer">
      <div>
        <Button
          disabled={
            totalPieces === 0 || minItemsSellIn > totalPieces || loading
          }
          onClick={() => handleMakeSellInOrder()}
          className="btn-primary btn-primary--black"
        >
          {isOrderPerformed
            ? formatMessage({ id: 'sell-in.repeat-order' })
            : formatMessage({ id: 'request-order' })}
          {loading && loadingSpinner__small}
        </Button>
      </div>
      {minItemsSellIn > totalPieces && (
        <div className="small-body">
          <b>
            {formatMessage(
              { id: 'sell-in.request-{minItems}' },
              { minItems: minItemsSellIn }
            )}
          </b>
        </div>
      )}
    </Row>
  );

  const requestSendModal = (
    <CustomModal
      visible={showCompleteReportModal}
      content={
        <div className="sell-out__report-complete-modal">
          <p className="section-title">
            {formatMessage({ id: 'sell-in.request-send-modal.title' })}
          </p>
          <p className="regular-body">
            {formatMessage({ id: 'sell-in.request-send-modal.text' })}
          </p>
        </div>
      }
      showIcon={true}
      centered={true}
      footer={false}
      onCancel={() => setShowCompleteReportModal(false)}
    />
  );

  const labelToShow = (
    <Row justify="center" className="sell-in__labels">
      {isCompleted ? (
        <Col
          span={18}
          className="challengeResourceWrapper__label challengeResourceWrapper__label--completed"
        >
          {formatMessage({ id: 'sell-in.label-messages.request-sent' })}
        </Col>
      ) : isManager(userStatus) ? null : (
        <Col span={20} className="container-repeat-quiz">
          {formatMessage({
            id: 'sell-in.label-messages.only-manager-can-send-request',
          })}
        </Col>
      )}
    </Row>
  );

  const component = (
    <Col>
      {conditionsComponent}
      {rangesImagesComponent}
      {productsComponent}
      {isManager(userStatus) && resumeComponent}
      {labelToShow}
      {isManager(userStatus) && footerComponent}
      {requestSendModal}
      <CustomModal
        closeIcon={<CustomCloseIcon />}
        centered
        content={
          <Col className="custom-modal-container" span={24}>
            <Row
              justify="start"
              align="middle"
              className="conditions-modal__title"
            >
              {formatMessage({ id: 'shopping-cart.conditions-modal.title' })}
            </Row>
            <div
              className="conditions-modal__content block--small regular-body"
              dangerouslySetInnerHTML={{
                __html: conditions,
              }}
            />
          </Col>
        }
        customClass="challengeDetail--sell-in shopping-cart-modal conditions-modal"
        hideFooter={true}
        onCancel={() => setShowConditionsModal(false)}
        showIcon={false}
        visible={showConditionsModal}
      />
    </Col>
  );

  return (
    <ChallengeResourceWrapper
      children={component}
      description={description}
      icon={icon}
      styleClass="challengeDetail--sell-in"
      title={title}
      points={
        points > 0 &&
        formatMessage(
          { id: 'page.challenge.plus-sign-{points}-points' },
          { points: formatNumber(points) }
        )
      }
    />
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    userStatus: state.auth.userStatus,
  };
};

export default connect(mapStateToProps)(ChallengeSellIn);
