import {
  useSize,
  YuFlex,
  YuSkeleton,
  YuSkeletonWrapper,
} from '@isdin/yuma-react-web-pin';
import React from 'react';
import ConditionalRenderer from 'src/components/ConditionalRenderer';
import { isUSAPlatform } from '../../../../utils';

const SkeletonAccountLayoutFunc = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const { upS, upM } = useSize();

  return (
    <>
      <YuFlex flexDirection={upS ? 'row' : 'column'}>
        <ConditionalRenderer
          condition={upM}
          trueComponent={
            <YuSkeletonWrapper
              paddingLeft="L"
              paddingRight="M"
              paddingTop="L"
              minWidth="400px"
              width="400px"
              backgroundColor="bg.grey"
            >
              <YuFlex gap="L" flexDirection="column">
                <YuSkeletonWrapper
                  style={{
                    boxShadow:
                      '0px 0px 1px 0px #1313134D, 0px 4px 8px 0px #13131333',
                  }}
                  maxWidth="1400px"
                  width="96%"
                  borderRadius="4px"
                  backgroundColor="bg.grey"
                  padding="XS"
                >
                  <YuFlex flexDirection="row" gap="L">
                    <YuFlex gap="XXS" flexDirection="column">
                      <YuSkeleton height="16px" width="71px" />
                      <YuSkeleton height="24px" width="154px" />
                    </YuFlex>
                    <YuSkeleton height="56px" width="56px" />
                  </YuFlex>
                </YuSkeletonWrapper>
                <YuFlex gap="S" flexDirection="column">
                  <YuFlex flexDirection="row" gap="XXS">
                    <YuSkeleton height="16px" width="24px" />
                    <YuSkeleton height="16px" width="91px" />
                  </YuFlex>
                  <YuFlex flexDirection="row" gap="XXS">
                    <YuSkeleton height="16px" width="24px" />
                    <YuSkeleton height="16px" width="116px" />
                  </YuFlex>
                  <YuFlex flexDirection="row" gap="XXS">
                    <YuSkeleton height="16px" width="24px" />
                    <YuSkeleton height="16px" width="140px" />
                  </YuFlex>
                  <YuFlex flexDirection="row" gap="XXS">
                    <YuSkeleton height="16px" width="24px" />
                    <YuSkeleton height="16px" width="116px" />
                  </YuFlex>
                  {isUSAPlatform ? (
                    <YuFlex flexDirection="row" gap="XXS">
                      <YuSkeleton height="16px" width="24px" />
                      <YuSkeleton height="16px" width="116px" />
                    </YuFlex>
                  ) : null}
                </YuFlex>
              </YuFlex>
            </YuSkeletonWrapper>
          }
        />

        {children}
      </YuFlex>
    </>
  );
};

export const SkeletonAccountLayout = React.memo(SkeletonAccountLayoutFunc);
