import { Checkbox, Row } from 'antd';
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { builderLegalCheck } from 'src/components/challenge/builder';
import { IResourceList } from 'src/components/challenge/ChallengeInterfaces';
import { IRootReducers } from 'src/reducers';
import { ResourceTypeEnum } from 'src/shared/enums';
import { IChallengeLegalCheck } from 'src/shared/models';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { customFormatMessage } from '../../../utils';

interface OwnProps {
  config?: IChallengeLegalCheck;
  mainResource?: IResourceList;
  isCompleted?: boolean;
  updateLegalData: Dispatch<
    SetStateAction<{
      isAccepted: boolean;
      idResource: number;
    }>
  >;
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps;

const ChallengeLegalCheck: FC<Props> = ({
  mainResource,
  updateLegalData,
  user,
  isCompleted,
  config: originalConfig,
}) => {
  const [isAccepted, setIsAccepted] = useState<boolean>(false);
  const shouldRenderInChallengeHeader =
    originalConfig?.shouldRenderInChallengeHeader;
  useEffect(() => {
    setIsAccepted(originalConfig?.wasPerformed || originalConfig?.wasAccepted);
  }, []);

  if (originalConfig) {
    return (
      <Row
        className={`legal-check address-row-legal-content${
          shouldRenderInChallengeHeader ? '-start' : ''
        }`}
      >
        <Checkbox
          disabled={isCompleted}
          checked={isCompleted || isAccepted}
          onChange={(event: CheckboxChangeEvent) => {
            setIsAccepted(event.target.checked);
            updateLegalData({
              isAccepted: event.target.checked,
              idResource: originalConfig.idResource,
            });
          }}
        />
        <a
          className={`address-checkbox-legal${isCompleted ? ' disabled' : ''}`}
          href={originalConfig.legalUrl}
          target="_blank"
        >
          {customFormatMessage('challenge.legal_check')}
        </a>
      </Row>
    );
  }

  const resourceLegalCheck = mainResource?.resourceList?.find(
    ({ idResourceType: { idResourceType }, status }) =>
      idResourceType === ResourceTypeEnum.LEGAL_CHECK && status
  );

  if (!resourceLegalCheck) return <React.Fragment />;

  const { usersM2MList } = resourceLegalCheck;
  const userExist = usersM2MList.find(
    (userResource) => userResource.updateUser === user.idUser
  );

  if (!resourceLegalCheck || userExist) return <React.Fragment />;

  const config = builderLegalCheck(resourceLegalCheck);

  const { legalUrl } = config;

  const handleChange = (event: CheckboxChangeEvent) => {
    updateLegalData({
      isAccepted: event.target.checked,
      idResource: resourceLegalCheck.idResource,
    });
  };

  return (
    <Row justify="center" className="address-row-legal-content">
      <Checkbox onChange={handleChange} />
      <a className="address-checkbox-legal" href={legalUrl} target="_blank">
        {customFormatMessage('challenge.legal_check')}
      </a>
    </Row>
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(ChallengeLegalCheck);
