import React, { FC, useState } from 'react';
import { config } from 'config/config';
import { IShoppingProduct } from './ShoppingCart.utils';
import { Col, InputNumber, Modal, Row } from 'antd';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { CustomCloseIcon } from './ShoppingCart';
import { IChallengeResourceConfig, ISellInProduct } from '../../shared/models';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { getCurrencyFormat } from '../../../utils';

interface IProps {
  config: IChallengeResourceConfig;
  handleChangeQuantity: (idProduct: number, quantity: number) => void;
  product: IShoppingProduct | ISellInProduct;
  withBorderInput?: boolean;
  showQuantitySelector?: boolean;
  showPrices?: boolean;
}

const Product: FC<IProps> = ({
  config: cartConfig,
  handleChangeQuantity,
  product,
  withBorderInput = false,
  showQuantitySelector = true,
  showPrices = true,
}) => {
  const {
    description: productDescription,
    idProduct,
    idResource,
    image,
    maxItems,
    minItems,
    name: productName,
    quantity,
  } = product;

  const [showImageModal, setShowImageModal] = useState(false);
  const productImage = image ? image : config.APP.BASE_URL + '/noimage.png';

  const increaseItems = () => {
    handleChangeQuantity(idResource, quantity + 1);
  };

  const reduceItems = () => {
    handleChangeQuantity(idResource, quantity - 1);
  };

  const handleBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
    handleChangeQuantity(idResource, +evt.target.value);
  };

  const quantitySelector = !cartConfig.isCompleted && (
    <div
      className={`product-input ${
        withBorderInput ? 'product-input--with-border' : ''
      }`}
    >
      <Row justify="center" align="middle" className="product-input-container">
        <Col span={!withBorderInput && 7} onClick={reduceItems}>
          <button>
            <MinusCircleOutlined />
          </button>
        </Col>
        <Col span={!withBorderInput && 10}>
          <InputNumber
            className="input-number"
            value={quantity}
            onBlur={handleBlur}
            min={minItems || 0}
            max={maxItems || 999}
          />
        </Col>
        <Col span={!withBorderInput && 7} onClick={increaseItems}>
          <button>
            <PlusCircleOutlined />
          </button>
        </Col>
      </Row>
    </div>
  );

  const productComponent = (
    <div className="shopping-product">
      <div
        className="product-image-container"
        onClick={() => setShowImageModal(true)}
      >
        <img
          className="product-image"
          loading="lazy"
          alt={`product-${idProduct}`}
          src={productImage}
        />
        <div className="icon-container">
          <i className="icon icon--search"></i>
        </div>
      </div>

      <Row justify="center" align="middle" className="product-name">
        <p>{productName}</p>
      </Row>

      <Row justify="center" align="middle" className="product-detail">
        <p dangerouslySetInnerHTML={{ __html: productDescription }} />
      </Row>

      {showPrices && product?.pvpr && (
        <Row justify="center" align="middle" className="product-price">
          <FormattedNumber
            value={product.pvpr * quantity}
            style="currency"
            currency={getCurrencyFormat()}
          />
        </Row>
      )}

      {showQuantitySelector &&
        (product.stock >= product.minItems && product.stock !== 0 ? (
          quantitySelector
        ) : (
          <Row
            justify="center"
            align="middle"
            className="product-input-container"
          >
            <Col className="no-stock-message">
              <FormattedMessage id="product.no-stock-message" />
            </Col>
          </Row>
        ))}

      <Modal
        visible={showImageModal}
        footer={null}
        onCancel={() => setShowImageModal(false)}
        className="shopping-cart-modal view-product"
        closeIcon={<CustomCloseIcon />}
      >
        <Row
          justify="center"
          align="middle"
          className="shopping-cart-modal__row"
        >
          <img
            className="product-image"
            loading="lazy"
            alt={`product-${idProduct}`}
            src={productImage}
          />
          <div className="product-name">{productName}</div>
          <div className="product-detail">
            <div dangerouslySetInnerHTML={{ __html: productDescription }} />
          </div>
        </Row>
      </Modal>
    </div>
  );

  return productComponent;
};

export default Product;
