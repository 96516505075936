import { Button, Col, Row } from 'antd';
import React, { useState } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { QuizQuestionTypeEnum } from 'src/shared/enums';
import { IQuizAnswer, IQuizQuestion } from 'src/shared/models';
import {
  customFormatMessage,
  GetEmbedVideoIframe,
  getTranslatedData,
} from '../../../utils';

interface IQuestion {
  saveAnswer: (idResourceAnswer: number) => void;
  handleSubmit: (idResourceAnswer?: number | number[]) => void;
  showStatistics: boolean;
  allowContinue: boolean;
  totalResponses: number;
  questions: IQuizQuestion[];
}

const Question = ({
  saveAnswer,
  handleSubmit,
  allowContinue, // User can do the next question without select the correct answer
  questions,
  showStatistics,
}: IQuestion): JSX.Element => {
  const [userAnswer, setUserAnswer] = useState<null | IQuizAnswer[]>(null);
  const [showAnswer, setShowAnswer] = useState<boolean>(false);
  const [showCorrect, setShowCorrect] = useState<boolean>(false);
  const [canContinue, setCanContinue] = useState<boolean>(false);
  const [answersSelectable, setAnswersSelectable] = useState<boolean>(true);
  const [question, setQuestion] = useState<IQuizQuestion>(questions[0]);
  const [questionNumber, setQuestionNumber] = useState<number>(0);
  const [responses, setResponses] = useState<number[]>([]);
  const [isValidated, setIsValidated] = useState<boolean>(false);

  const quizType = question.questionType || QuizQuestionTypeEnum.UNIQUE;
  const isUniqueResponse = quizType === QuizQuestionTypeEnum.UNIQUE;
  const isMultiresponse = quizType === QuizQuestionTypeEnum.MULTIRESPONSE;
  const isAllValid = quizType === QuizQuestionTypeEnum.ALL_VALID;

  let totalUserResponsesFromQuestion = 0;

  question?.answers?.forEach((answer) => {
    totalUserResponsesFromQuestion += answer.userResponses;
  });

  const showQuestionStatistics =
    showStatistics || question.questionShowStatistics;

  const statisticsColumnsSize = showQuestionStatistics && showCorrect ? 3 : 0;

  const isSingleMedia =
    (question.urlImage.length === 1 && !question.hasVideo) ||
    (question.hasVideo && question.urlImage.length === 0);

  const handleConfirm = () => {
    const checkCorrection = answersSelectable;
    const goToNextQuestion = !question.isLastQuestion;
    const finalizeQuiz = !checkCorrection && question.isLastQuestion;

    switch (true) {
      case goToNextQuestion:
        responses.map((_response) => saveAnswer(_response));
        setUserAnswer(null);
        setShowAnswer(false);
        setAnswersSelectable(true);
        setShowCorrect(false);
        setCanContinue(false);
        setQuestionNumber(questionNumber + 1);
        setQuestion(questions[questionNumber + 1]);
        setResponses([]);
        setIsValidated(false);
        break;
      case finalizeQuiz:
        handleSubmit(responses);
        setCanContinue(false);
        break;
    }
  };

  const handleSelectAnswer = (_answer: IQuizAnswer) => {
    if (!answersSelectable) return;

    const isResponseSaved = responses.find(
      (_answers) => _answers === _answer.idResource
    );

    if (isResponseSaved) {
      const updatedResponses = responses.filter(
        (_response) => _response !== _answer.idResource
      );

      setResponses(updatedResponses);

      if (updatedResponses.length <= 0) setCanContinue(false);
    } else {
      setResponses([...responses, _answer.idResource]);
      setCanContinue(true);
    }

    const selectCorrectAnswer_isRequired = false;
    const accept_one_response = true;

    if (isUniqueResponse || isAllValid)
      switch (allowContinue) {
        case selectCorrectAnswer_isRequired:
          setShowAnswer(true);
          setShowCorrect(_answer.isCorrect);
          setAnswersSelectable(!_answer.isCorrect);
          setCanContinue(_answer.isCorrect);
          setUserAnswer(_answer.isCorrect ? [_answer] : null);
          break;

        case accept_one_response:
          setUserAnswer([_answer]);
          setShowAnswer(true);
          setCanContinue(true);
          setAnswersSelectable(false);
          setShowCorrect(true);
          break;
      }
  };

  const handleValidate = () => {
    setIsValidated(true);
    setCanContinue(true);
    setShowCorrect(true);
    setShowAnswer(true);
    setAnswersSelectable(false);
    const answersSelected = responses.map(
      (response) =>
        question?.answers?.filter((answer) => answer.idResource === response)[0]
    );
    setUserAnswer(answersSelected);
  };

  const renderQuestionButton = (): JSX.Element => {
    let label: string;

    switch (quizType) {
      case QuizQuestionTypeEnum.UNIQUE:
        label = question.isLastQuestion
          ? 'page.challenge.quiz.finalize'
          : 'page.challenge.quiz.next';
        break;
      case QuizQuestionTypeEnum.MULTIRESPONSE:
        if (!isValidated) {
          label = 'page.challenge.quiz.validate';
        } else {
          label = question.isLastQuestion
            ? 'page.challenge.quiz.finalize'
            : 'page.challenge.quiz.next';
        }
        break;
      case QuizQuestionTypeEnum.ALL_VALID:
        label = question.isLastQuestion
          ? 'page.challenge.quiz.finalize'
          : 'page.challenge.quiz.next';
        break;
    }

    const icon = answersSelectable ? null : (
      <a className="icon icon__white icon--arrow-normal-right" />
    );

    if (isMultiresponse && !isValidated) {
      return (
        <Row className="button-container" justify="end">
          <Col md={12 - statisticsColumnsSize} xs={24}>
            <Row justify="end">
              <Button
                block
                disabled={!canContinue}
                onClick={handleValidate}
                className="btn-primary btn-primary--black button-container__content"
              >
                <Row justify="space-between" align="middle">
                  <FormattedMessage id={label} />
                  {icon}
                </Row>
              </Button>
            </Row>
          </Col>
        </Row>
      );
    }

    return (
      <Row className="button-container" justify="end">
        <Col md={12 - statisticsColumnsSize} xs={24}>
          <Row justify="end">
            <Button
              block
              disabled={!canContinue}
              onClick={handleConfirm}
              className="btn-primary btn-primary--black button-container__content"
            >
              <Row justify="space-between" align="middle">
                <FormattedMessage id={label} />
                {icon}
              </Row>
            </Button>
          </Row>
        </Col>
        {showQuestionStatistics && <Col md={statisticsColumnsSize} xs={0} />}
      </Row>
    );
  };

  const renderVideo = (question: IQuizQuestion): JSX.Element => {
    const { urlVideo, isVideoFromFile } = question;

    let el: React.ReactNode;

    if (isVideoFromFile) {
      el = (
        <video controls>
          <source src={urlVideo} type="video/mp4" />
        </video>
      );
    } else {
      el = <GetEmbedVideoIframe url={urlVideo} />;
    }
    return (
      <Col xs={24} sm={isSingleMedia ? 24 : 12} md={isSingleMedia ? 24 : 12}>
        <div
          className={
            question.videoIsVertical
              ? 'question-video-container-v'
              : 'question-video-container-h'
          }
        >
          <div
            className={
              question.videoIsVertical ? 'question-video-v' : 'question-video-h'
            }
          >
            {el}
          </div>
        </div>
      </Col>
    );
  };

  const renderImage = (value: string[]) =>
    value.map((_img) => {
      return (
        <Col
          xs={24}
          sm={isSingleMedia ? 24 : 12}
          md={isSingleMedia ? 24 : 12}
          className="question-media-col"
        >
          <img
            loading="lazy"
            className={
              isSingleMedia
                ? 'question-media-one question-media-image'
                : 'question-media question-media-image'
            }
            src={_img}
            alt="event_image"
          />
        </Col>
      );
    });

  const renderChallengeMedia = (question.hasImage || question.hasVideo) && (
    <Row
      justify={isSingleMedia ? 'center' : 'start'}
      gutter={isSingleMedia ? [0, 0] : [24, 24]}
      className="question-media-row"
    >
      {question.hasVideo && renderVideo(question)}
      {question.urlImage.length > 0 && renderImage(question.urlImage)}
    </Row>
  );

  const renderStatistics = (answer: IQuizAnswer): JSX.Element => {
    const { idResource, userResponses } = answer;
    const answerSelected = userAnswer?.find(
      (_answer) => _answer.idResource == idResource
    )
      ? true
      : false;

    const _usersResponses = userResponses + (answerSelected ? 1 : 0);

    const _totalUsersResponses =
      totalUserResponsesFromQuestion + (userAnswer ? 1 : 0);

    const _value = _usersResponses / _totalUsersResponses || 0;

    return showQuestionStatistics && showCorrect ? (
      <Col
        className="question-statistics"
        md={statisticsColumnsSize}
        xs={2 + statisticsColumnsSize}
      >
        <Row justify="center" align="middle">
          <FormattedNumber value={_value} style="percent" />
        </Row>
      </Col>
    ) : null;
  };

  const renderAnswer = (answer: IQuizAnswer, key: number) => {
    const { idResource, isCorrect, name } = answer;
    const answerSelected = responses.includes(idResource);
    const selectableClass = answerSelected ? 'selected' : '';
    const incorrectAnswer = answerSelected ? 'incorrect' : '';
    const correctionClass = answerSelected
      ? 'correct'
      : isMultiresponse
      ? 'valid'
      : '';

    let answerClass: string;
    let iconClass: 'check' | 'close';

    switch (quizType) {
      case QuizQuestionTypeEnum.ALL_VALID:
        answerClass = showAnswer && answerSelected ? correctionClass : '';
        iconClass = showAnswer && answerSelected ? 'check' : 'close';
        break;

      case QuizQuestionTypeEnum.MULTIRESPONSE:
      case QuizQuestionTypeEnum.UNIQUE:
      default:
        iconClass = !showAnswer ? 'check' : isCorrect ? 'check' : 'close';
        if (isCorrect) {
          answerClass = showAnswer ? correctionClass : selectableClass;
        } else {
          answerClass = showAnswer ? incorrectAnswer : selectableClass;
        }
        break;
    }

    return (
      <Row
        className="question-answer-container"
        onClick={() => !isValidated && handleSelectAnswer(answer)}
        key={key}
      >
        <Col
          className={`question-answer question-answer--${answerClass}`}
          md={24 - statisticsColumnsSize}
          xs={
            (showQuestionStatistics && showCorrect ? 22 : 24) -
            statisticsColumnsSize
          }
        >
          <Row justify="space-between">
            <Col className="answer-title" xs={20} lg={22}>
              {getTranslatedData(answer, 'content') || name}
            </Col>
            <Col className={`answer answer--${answerClass}`} xs={4} lg={2}>
              <Row justify="center" align="middle">
                <a
                  className={`icon icon__increase_50 icon__white icon--${iconClass}`}
                />
              </Row>
            </Col>
          </Row>
        </Col>
        {renderStatistics(answer)}
      </Row>
    );
  };

  const renderQuestion = question?.answers?.map((answer, key: number) =>
    renderAnswer(answer, key)
  );

  const renderQuestionDescription = question.description && (
    <Row justify="start">
      <Row align="middle">
        <Col md={24 - statisticsColumnsSize} xs={22 - statisticsColumnsSize}>
          <Row className="question-description">
            <div
              dangerouslySetInnerHTML={{ __html: question.description }}
            ></div>
          </Row>
        </Col>
      </Row>
    </Row>
  );

  const renderQuestions = (
    <Row justify="start">
      <Row align="middle">
        <Col md={24 - statisticsColumnsSize} xs={22 - statisticsColumnsSize}>
          <Row className="question-title">
            {getTranslatedData(question, 'content') || question.title}
          </Row>
        </Col>
        {showQuestionStatistics && showCorrect && (
          <Col
            className="question-title-statistics"
            md={statisticsColumnsSize}
            xs={statisticsColumnsSize + 2}
          >
            <FormattedMessage id="page.challenge.quiz.average-answer" />
          </Col>
        )}
      </Row>
      {renderQuestion}
      {showCorrect && question?.hasJustification && (
        <Col className="question-justification" md={24} xs={24}>
          <Row className="regular-body">
            {getTranslatedData(question, 'content') || (
              <div
                dangerouslySetInnerHTML={{ __html: question.textJustification }}
              />
            )}
          </Row>
        </Col>
      )}
    </Row>
  );

  const renderQuestionCounter = (
    <Row className="question-counter" justify="start">
      <p>
        {customFormatMessage('page.challenge.quiz.question-{current}-{total}', {
          current: questionNumber + 1,
          total: questions.length,
        })}
      </p>
    </Row>
  );

  const renderTraining = (
    <Row justify="center" className="quiz-question-container">
      {renderChallengeMedia}
      {renderQuestionDescription}
      {renderQuestionCounter}
      {renderQuestions}
      {renderQuestionButton()}
    </Row>
  );

  return renderTraining;
};

export default Question;
