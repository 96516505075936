import { Row } from 'antd';
import { config as appConfig } from 'config/config';
import { DateTime } from 'luxon';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IResourceList } from 'src/components/challenge/ChallengeInterfaces';
import {
  CenterRolesEnum,
  ResourceTypeDetailEnum,
  ResourceTypeEnum,
  SellOutStatusEnum,
} from 'src/shared/enums';
import {
  ISellOut,
  IUser,
  IUserUploadMedia,
  IUserUploadMediaData,
} from 'src/shared/models';
import { getTimeZone } from 'utils/timeUtils';
import { checkUserStatus } from '../../../../utils';

const {
  SELL_OUT_DESCRIPTION,
  SELL_OUT_BODY_TITLE,
  SELL_OUT_BODY_DESCRIPTION,
  SELL_OUT_BODY_MAIN_IMAGE,
  SELL_OUT_BODY_START_DATE,
  SELL_OUT_BODY_END_DATE,
  SELL_OUT_DESCRIPTION_LARGE,
  SELL_OUT_REPORT_FILE_TYPE,
  SELL_OUT_REPORT_QUANTITY,
} = ResourceTypeDetailEnum;

const managerSignedInStates = [
  SellOutStatusEnum.REGISTERED,
  SellOutStatusEnum.PENDING,
  SellOutStatusEnum.VALIDATED,
  SellOutStatusEnum.DENIED,
];

const managerHasUploadStates = [
  SellOutStatusEnum.PENDING,
  SellOutStatusEnum.VALIDATED,
  SellOutStatusEnum.DENIED,
];

const allowedExtensions = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'file/pdf',
  'file/doc',
];

export const buildSellOut = (
  resource: IResourceList,
  user: IUser
): ISellOut => {
  const {
    resourceDetailList,
    idResource,
    name: title,
    resourceList,
    score,
  } = resource;
  let item: ISellOut;
  let visibility: IUserUploadMedia;
  let points = 0;
  let minQuantity: number;
  let maxQuantity: number;

  resourceDetailList
    .filter(({ status }) => status)
    .forEach((_item) => {
      switch (_item.idResourceTypeD.idResourceTypeD) {
        case SELL_OUT_DESCRIPTION:
          item = {
            ...item,
            description: _item.value,
          };
          break;

        case SELL_OUT_BODY_TITLE:
          item = {
            ...item,
            bodyTitle: _item.value,
          };
          break;

        case SELL_OUT_BODY_DESCRIPTION:
          item = {
            ...item,
            bodyDescription: _item.value,
          };
          break;

        case SELL_OUT_BODY_MAIN_IMAGE:
          item = {
            ...item,
            bodyImage:
              _item.value || appConfig.APP.MARKETING_MATERIALS.DEFAULT_IMAGE_BG,
          };
          break;

        case SELL_OUT_BODY_START_DATE:
          item = {
            ...item,
            bodyStartDate: _item.value,
          };
          break;

        case SELL_OUT_BODY_END_DATE:
          item = {
            ...item,
            bodyEndDate: _item.value,
          };
          break;

        case SELL_OUT_DESCRIPTION_LARGE:
          item = {
            ...item,
            descriptionLarge: _item.value,
          };
          break;

        case SELL_OUT_REPORT_FILE_TYPE:
          item = {
            ...item,
            reportFileType: _item.value,
          };
          break;

        case SELL_OUT_REPORT_QUANTITY:
          item = {
            ...item,
            reportedQuantityTitle: _item.value,
          };
          break;
      }
    });

  const fileData = resource.usersM2MList.find(({ status }) => status);
  const { textMachine, idUserResource, value, statusResource } = fileData || {};
  const [fileName, size] = textMachine?.split('|') ?? ['', ''];

  const mediaData: IUserUploadMediaData = {
    fileName,
    idUserResource,
    isFirstTime: resource.usersM2MList.length === 0,
    isUploaded: !!fileData,
    size,
    statusResource: statusResource || SellOutStatusEnum.NOT_REGISTERED,
    type: visibility?.mediaData?.type,
    url: value,
  };

  const isManager = user.usersCenter[0]?.idRole === CenterRolesEnum.MANAGER;
  const isSignedIn = checkUserStatus(resource, managerSignedInStates);
  const hasUpload = checkUserStatus(resource, managerHasUploadStates);
  const hasStarted =
    DateTime.now().setZone(getTimeZone()) >
    DateTime.fromJSDate(new Date(item.bodyStartDate)).setZone(getTimeZone());
  const hasExpired =
    DateTime.now().setZone(getTimeZone()) >
    DateTime.fromJSDate(new Date(item.bodyEndDate)).setZone(getTimeZone());

  const showLabelToReport = isSignedIn && isManager;
  const showLabelToUploadMedia =
    !item.isCompleted && isSignedIn && isManager && hasStarted;
  resourceList
    .filter(({ status }) => status)
    .forEach((_itemResource) => {
      let newPoints: number;
      switch (_itemResource.idResourceType.idResourceType) {
        case ResourceTypeEnum.SELL_OUT_POINTS_RANGE:
          newPoints = _itemResource.score?.points ?? 0;
          points = newPoints > points ? newPoints : points;

          _itemResource.resourceDetailList
            .filter(
              ({ status, idResourceTypeD: { idResourceTypeD } }) =>
                status &&
                idResourceTypeD ===
                  ResourceTypeDetailEnum.SELL_OUT_POINTS_RANGE_MIN
            )
            .forEach((_itemResourceDetails) => {
              const newMinQuantity = parseInt(_itemResourceDetails.value);
              minQuantity =
                newMinQuantity > minQuantity ? minQuantity : newMinQuantity;
            });

          _itemResource.resourceDetailList
            .filter(
              ({ status, idResourceTypeD: { idResourceTypeD } }) =>
                status &&
                idResourceTypeD ===
                  ResourceTypeDetailEnum.SELL_OUT_POINTS_RANGE_MAX
            )
            .forEach((_itemResourceDetails) => {
              const newMaxQuantity = parseInt(_itemResourceDetails.value);
              maxQuantity =
                newMaxQuantity > maxQuantity ? newMaxQuantity : maxQuantity;
            });
          break;
      }
    });

  // Add sign in points from sellout resource
  points += score?.points ?? 0;

  return {
    ...item,
    allowedExtensions,
    hasUpload,
    icon: 'challenge',
    idResource,
    isManager,
    isSignedIn,
    mediaData,
    minQuantity,
    maxQuantity,
    noManagerLabel: isManager ? (
      <React.Fragment />
    ) : (
      getManagerStatus(mediaData.statusResource as SellOutStatusEnum)
    ),
    points,
    showLabelToReport,
    showLabelToUploadMedia,
    title,
    hasExpired,
  };
};

export const getManagerStatus = (
  statusResource: SellOutStatusEnum
): JSX.Element => {
  let message_id = 'page.challenge.sell-out.your-manager-entry-status';
  let class_id = 'challengeResourceWrapper__label';

  switch (statusResource) {
    case SellOutStatusEnum.NOT_REGISTERED:
      message_id = `${message_id}-not-registered`;
      class_id = `${class_id}--pending`;
      break;
    case SellOutStatusEnum.REGISTERED:
      message_id = `${message_id}-registered`;
      class_id = `${class_id}--completed`;
      break;
    case SellOutStatusEnum.DENIED:
      message_id = `${message_id}-deny`;
      class_id = `${class_id}--error`;
      break;
    case SellOutStatusEnum.PENDING:
      message_id = `${message_id}-uploaded`;
      class_id = `${class_id}--completed`;
      break;
    case SellOutStatusEnum.VALIDATED:
      message_id = `${message_id}-valid`;
      class_id = `${class_id}--completed`;
      break;
    default:
      return <React.Fragment />;
  }

  return (
    <Row justify="center" align="middle">
      <div className={`challengeResourceWrapper__label ${class_id}`}>
        <FormattedMessage id={message_id} />
      </div>
    </Row>
  );
};
