import { useIntl } from 'react-intl';
import { Col, Row } from 'antd';
import { config as configApp } from 'config/config';
import { default as React, FC } from 'react';
import { connect } from 'react-redux';
import { IRootReducers } from 'src/reducers';
import { ResourceTypeEnum } from 'src/shared/enums';
import {
  IChallengeResource,
  IChallengeResourceConfig,
  IChallengeResourceGuide,
} from 'src/shared/models';
import { scrollDown } from '../../../utils';

const { RESOURCES_GUIDE } = ResourceTypeEnum;

interface OwnProps {
  children?: React.ReactNode;
}

type Props = ReturnType<typeof mapStateToProps> & OwnProps;

const ChallengeResourceGuide: FC<Props> = ({
  modules,
  resourceGuideConfig,
}) => {
  const targetModules = modules?.filter(
    ({ type }) => ![RESOURCES_GUIDE].includes(type)
  );

  if (!targetModules || !resourceGuideConfig) return <React.Fragment />;

  const { showPoints, title, description } =
    resourceGuideConfig as IChallengeResourceGuide;

  return (
    <Row className="resource-guide" justify="center">
      <Col span={24} className="resource-guide__header">
        <h4 className="resource-guide__header--title h4">{title}</h4>
        <p className="resource-guide__header--description">{description}</p>
      </Col>
      <Row className="resource-guide__container-modules" justify="center">
        {renderTargetModules({
          modules,
          targetModules,
          showPoints,
        })}
      </Row>
    </Row>
  );
};

const renderTargetModules = (props: {
  showPoints: boolean;
  targetModules: IChallengeResource[];
  modules: IChallengeResource[];
}): JSX.Element[] => {
  const { showPoints, targetModules, modules } = props;

  return targetModules?.map((targetModule, index) => {
    const { isVisible } = targetModule.config as IChallengeResourceConfig;

    if (!isVisible) return <React.Fragment />;

    const isDepResourceComplete = (
      modules.find(
        ({ resource: { idResource } }) =>
          idResource === targetModule.resource.depResource
      )?.config as IChallengeResourceGuide
    )?.isCompleted;

    const isLastElement = index === targetModules.length;
    const showDependencyLine =
      index !== 0 && Boolean(targetModule.resource.depResource);

    return (
      <Col
        className={`container-module ${
          isLastElement ? '' : 'container-module--not-last'
        }`}
        key={`container-module--${targetModule.key}`}
        xs={24}
        sm={24}
        md={null}
        style={{ position: 'relative' }}
      >
        <Row justify="center">
          <ResourceGuideModule
            {...{
              position: targetModules.length - index,
              module: targetModule,
              showPoints,
              dependencyLine: showDependencyLine && (
                <div
                  className={`resource-guide--dependency-line ${
                    isDepResourceComplete ? 'done' : 'blocked'
                  }`}
                />
              ),
            }}
          />
        </Row>
      </Col>
    );
  });
};

const ResourceGuideModule = ({
  dependencyLine,
  module,
  position,
  showPoints,
}: {
  dependencyLine?: JSX.Element;
  module: IChallengeResource;
  position: number;
  showPoints: boolean;
}) => {
  const { isCompleted, isBlocked, title, points } =
    module.config as IChallengeResourceGuide;
  const shouldRenderPoints =
    showPoints && points >= configApp.APP.SHOW_POINTS_LIMIT;
  const { formatMessage, formatNumber } = useIntl();
  return (
    <Row
      justify="space-between"
      key={`module-details--${module.key}`}
      className="module-details"
      onClick={() => scrollDown(module.key)}
      style={{ zIndex: position }}
    >
      <div className="container-icons">
        {dependencyLine}
        <div
          className={`icon icon__rounded icon--${
            module.config.icon || 'default'
          } ${isBlocked ? 'disabled' : ''}`}
        />

        {isCompleted && (
          <div className="icon-status icon__success icon__rounded">
            <div className={`icon icon--check`} />
          </div>
        )}

        {isBlocked && (
          <div className="icon-status icon__grey-primary icon__rounded">
            <div className={`icon icon--padlock`} />
          </div>
        )}
      </div>
      <div className="container-labels">
        <Col className="container-labels__wrapper">
          <p
            className={`container-labels__title ${isBlocked ? 'blocked' : ''}`}
          >
            {title}
          </p>
          {shouldRenderPoints && (
            <p
              className={`container-labels__points ${
                isBlocked ? 'blocked' : ''
              }`}
            >
              {formatMessage(
                { id: 'menu.section.personal.{points}-point' },
                { points: formatNumber(points) }
              )}
            </p>
          )}
        </Col>
      </div>
    </Row>
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    modules: state.app.challenge?.challengeResources,
    resourceGuideConfig: state.app.challenge?.challengeResourceGuide?.config,
  };
};

export default connect(mapStateToProps)(ChallengeResourceGuide);
