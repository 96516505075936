import {
  base_esES,
  deDE,
  enUS,
  esAR,
  esCL,
  esCO,
  esES,
  esGT,
  esMX,
  esPE,
  itIT,
  ptBR,
  ptPT,
} from '../messages';

const getAltLiterals = (mainLanguage, altLanguage) => {
  if (!mainLanguage || !altLanguage) return;

  return { ...mainLanguage, ...altLanguage };
};

const localeProviderMap = new Map<string, { [key: string]: string }>([
  ['es-ES', getAltLiterals(base_esES, esES)],
  ['es-AR', getAltLiterals(base_esES, esAR)],
  ['es-CL', getAltLiterals(base_esES, esCL)],
  ['es-CO', getAltLiterals(base_esES, esCO)],
  ['es-MX', getAltLiterals(base_esES, esMX)],
  ['es-PE', getAltLiterals(base_esES, esPE)],
  ['es-GT', getAltLiterals(base_esES, esGT)],
  ['de-DE', deDE],
  ['en-US', enUS],
  ['it-IT', itIT],
  ['pt-BR', ptBR],
  ['pt-PT', ptPT],
]);

export const intlLocaleProvider = (locale: string) => {
  return localeProviderMap.get(locale);
};

export const getTranslations = (translations) => {
  return {
    ['es-ES']: {
      ...translations['es-ES'],
      ...intlLocaleProvider('es-ES'),
    },
    ['en-US']: {
      ...translations['en-US'],
      ...intlLocaleProvider('en-US'),
    },
    ['de-DE']: {
      ...translations['de-DE'],
      ...intlLocaleProvider('de-DE'),
    },
    ['es-AR']: {
      ...translations['es-ES'],
      ...intlLocaleProvider('es-AR'),
    },
    ['es-CO']: {
      ...translations['es-ES'],
      ...intlLocaleProvider('es-CO'),
    },
    ['es-MX']: {
      ...translations['es-ES'],
      ...intlLocaleProvider('es-MX'),
    },
    ['es-PE']: {
      ...translations['es-ES'],
      ...intlLocaleProvider('es-PE'),
    },
    ['es-GT']: {
      ...translations['es-ES'],
      ...intlLocaleProvider('es-GT'),
    },
    ['es-CL']: {
      ...translations['es-ES'],
      ...intlLocaleProvider('es-CL'),
    },
    ['pt-PT']: {
      ...translations['pt-BR'],
      ...intlLocaleProvider('pt-PT'),
    },
    ['pt-BR']: {
      ...translations['pt-BR'],
      ...intlLocaleProvider('pt-BR'),
    },
    ['it-IT']: {
      ...translations['it-IT'],
      ...intlLocaleProvider('it-IT'),
    },
  };
};
