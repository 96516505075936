import { Col, Row } from 'antd';
import { NextPage } from 'next';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';

import { config } from 'config/config';
import routePaths from 'config/routes';
import { getDataCall } from 'services/api';
import apiPaths from 'services/apiPaths';
import { privateRoute } from 'services/privateRoute';
import { insertChallenges, updateChallenges } from 'src/actions/appActions';
import { EmptyList } from 'src/components';
import { builderChallenge } from 'src/components/challenge/builder';
import BuildPageChallenge, {
  showMoreButton,
} from 'src/components/challenge/ChallengeBuildPage';
import ChallengeCard from 'src/components/challenge/ChallengeCard';
import { IRootReducers } from 'src/reducers';
import { ChallengedTabsEnum, ChallengeTypeEnum } from 'src/shared/enums';
import {
  ChallengeCardButton,
  ChallengeDetail,
  IChallenge,
  IChallengesList,
  IResponseChallengesList,
} from 'src/shared/models';
import { ReduxPageContext } from 'src/store';
import {
  fetchInitialData,
  getTranslatedData,
  renderChallengeCard,
} from '../../../utils';
import SubHeader from 'src/components/SubHeader';

const PAGE_SIZE = 2;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const renderChallengeCertifiedCourse = (
  challenge: ChallengeDetail
): JSX.Element => {
  const { formatMessage } = useIntl();

  let userStatus: { idResource: number; status: number };
  const challengeCardButton: ChallengeCardButton = {
    className: 'challengeCard__link challengeCard__link--active',
    path: routePaths.PAGES.CAMPAIGN_DETAIL,
    title: formatMessage({ id: 'certificate_course.see-details' }),
  };

  const config: ChallengeDetail = {
    ...challenge,
    challengeName: getTranslatedData(challenge, 'name'),
    challengeDescription:
      getTranslatedData(challenge, 'description') ||
      getTranslatedData(challenge, 'descriptionLong'),
    challengeCardButton,
    userStatus,
  };

  return <ChallengeCard {...config} />;
};

const CertifiedCoursesPage: NextPage<Props> = ({
  certifiedCoursesList,
  totalChallenges,
  user,
  updateChallenges,
  accessToken,
}): JSX.Element => {
  const { formatMessage } = useIntl();
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const getCurrentListPage = () =>
    Math.floor(certifiedCoursesList.length / PAGE_SIZE);
  const [certifiedCourses, setCertifiedCourses] =
    useState<ChallengeDetail[]>(certifiedCoursesList);
  const [requestPage, setRequestPage] = useState<number>(getCurrentListPage);
  const [canShowMore, setCanShowMore] = useState<boolean>(
    totalChallenges > certifiedCoursesList?.length
  );

  const [filters, setFilters] = useState<ChallengedTabsEnum>(
    ChallengedTabsEnum.ALL
  );

  useEffect(() => {
    setCertifiedCourses(certifiedCoursesList);
    setCanShowMore(totalChallenges > certifiedCoursesList.length);
  }, []);

  const handleShowMore = async () => {
    setIsLoadingMore(true);

    const request = `${apiPaths.CHALLENGES.GET_CERTIFIED_COURSES}?page=${requestPage}&size=4`;

    try {
      const response = await getDataCall({
        dataPath: request,
        callConfig: {},
      });

      const ids = new Set(
        certifiedCourses.map((certifiedCourse) => certifiedCourse.challengeId)
      );

      const newchallengeAcc: ChallengeDetail[] = [
        ...certifiedCoursesList,
        ...response.data.challenges
          .filter(
            (certifiedCourse: IChallenge) =>
              !ids.has(certifiedCourse.idChallenge)
          )
          .map((certifiedCourse: IChallenge) =>
            builderChallenge(certifiedCourse, user, accessToken)
          ),
      ];

      updateChallenges({
        challengeType: 'certifiedCourses',
        challengeList: {
          total: response.data.total,
          challenges: newchallengeAcc,
        },
      });
      setRequestPage(requestPage + 1);
      const challengesLeft = totalChallenges - certifiedCoursesList.length;

      setCanShowMore(challengesLeft > response.data.challenges.length);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingMore(false);
    }
  };

  const showMoreComponent =
    filters == ChallengedTabsEnum.ALL
      ? showMoreButton(
          `page.marketing-materials.show-more`,
          isLoadingMore,
          canShowMore,
          handleShowMore
        )
      : null;

  const renderCertifiedCourses = (): JSX.Element | JSX.Element[] => {
    if (!certifiedCourses?.length)
      return <EmptyList page_name="certified_courses" />;

    return certifiedCourses.map((challenge: ChallengeDetail) =>
      renderChallengeCard({ challenge })
    );
  };

  const CustomHeadComponent = (
    <Col className="container-header">
      <Row className="container-header--headboard-image">
        <img
          className="container-header--headboard-image__desktop"
          loading="lazy"
          src={`${config.APP.ASSET_PREFIX}/formacion-acreditada.png`}
          alt="galleryProduct"
        />
        <img
          className="container-header--headboard-image__mobile"
          loading="lazy"
          src={`${config.APP.ASSET_PREFIX}/formacion-acreditada_mobile.jpg`}
          alt="galleryProduct"
        />
      </Row>
      <Row justify="center">
        <Col className="container-header--details">
          <Row
            className="container-header--details__title"
            justify="center"
            align="middle"
          >
            {formatMessage({ id: 'certified-course.details-title' })}
          </Row>

          <Row
            className="container-header--details__description"
            justify="center"
            align="middle"
          >
            {formatMessage({ id: 'certified-course.details-description' })}
          </Row>

          <Row
            className="container-header--details__steps"
            justify="space-around"
          >
            <Col className="step-container">
              <Row className="icon-container" justify="center" align="middle">
                <div className={'icon icon--academy'} />
              </Row>
              <Row className="text-containter" justify="center" align="middle">
                {formatMessage({ id: 'certified-course.academy' })}
              </Row>
            </Col>

            <Col className="step-container">
              <Row className="icon-container" justify="center" align="middle">
                <div className={'icon icon--timeFast'} />
              </Row>
              <Row className="text-containter" justify="center" align="middle">
                {formatMessage({ id: 'certified-course.time' })}
              </Row>
            </Col>

            <Col className="step-container">
              <Row className="icon-container" justify="center" align="middle">
                <div className={'icon icon--certificate'} />
              </Row>
              <Row className="text-containter" justify="center" align="middle">
                {formatMessage({ id: 'certified-course.certificate' })}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row
        className="container-header--title container-header--title__mobile"
        justify="center"
        align="middle"
      >
        {formatMessage({ id: 'certified-course.title' })}
      </Row>
      <Row
        className="container-header--title container-header--title__desktop"
        justify="center"
        align="middle"
      >
        {formatMessage({ id: 'certified-course.title' })}
      </Row>
    </Col>
  );

  return (
    <>
      <SubHeader />
      <BuildPageChallenge
        pageClassName="certifiedCoursesPage"
        onChangeTab={setFilters}
        renderChallenges={renderCertifiedCourses}
        showMoreComponent={showMoreComponent}
        headComponent={CustomHeadComponent}
        filterComponent={<React.Fragment />}
        challengeType={ChallengeTypeEnum.CERTIFIED_COURSE}
      />
    </>
  );
};

const initialAction = async (accessToken: string, ctx: ReduxPageContext) => {
  const state: IRootReducers = ctx.store.getState();

  const savedCertifiedCourses = state.app.challenges.certifiedCourses;

  let certifiedCourses: IChallengesList = {
    total: savedCertifiedCourses.total,
    challenges: savedCertifiedCourses.challenges,
    filters: savedCertifiedCourses.filters,
    filtersLoaded: savedCertifiedCourses.filtersLoaded,
  };

  try {
    const areLoadedCertifiedCourses = savedCertifiedCourses.total > 0;

    if (!areLoadedCertifiedCourses) {
      const response: IResponseChallengesList = await fetchInitialData(
        accessToken,
        `${apiPaths.CHALLENGES.GET_CERTIFIED_COURSES}?size=8`,
        ctx
      );

      const certifiedCoursesList: ChallengeDetail[] = response.challenges.map(
        (challenge: IChallenge) => builderChallenge(challenge, state.auth.user)
      );

      certifiedCourses = {
        total: response.total,
        challenges: certifiedCoursesList,
        filters: certifiedCourses.filters,
        filtersLoaded: savedCertifiedCourses.filtersLoaded,
      };
    }

    if (ctx.store.dispatch) {
      insertChallenges({
        challengeList: certifiedCourses,
        challengeKey: 'certifiedCourses',
      })(ctx.store.dispatch);
    }

    return {
      data: [],
    };
  } catch (error) {
    console.error('SERVER ERROR =>', error);
    return null;
  }
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    user: state.auth.user,
    certifiedCoursesList: state.app.challenges.certifiedCourses.challenges,
    totalChallenges: state.app.challenges.certifiedCourses.total,
    accessToken: state.auth.accessToken,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      updateChallenges,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(privateRoute({ WrappedComponent: CertifiedCoursesPage, initialAction }));
