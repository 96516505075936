import { Col, Row } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { config as configApp } from 'config/config';
import ItemsCarousel from 'react-items-carousel';
import ChallengeResourceWrapper, {
  ChallengeResourceWrapper_Blocked,
} from 'src/components/challenge/ChallengeResourceWrapper';
import {
  IChallengeMaterials,
  IChallengeResourceRender,
  IMaterial,
  MaterialTypeEnum,
} from 'src/shared/models';
import {
  completeChallengeUserResource,
  getTranslatedData,
} from '../../../utils';

const MIN_NUMBER_OF_CAROUSEL_CARD = 2;
const { DEFAULT_IMAGE_BG, DEFAULT_FILE_BG } = configApp.APP.MARKETING_MATERIALS;

interface OwnProps extends IChallengeResourceRender {
  config: IChallengeMaterials;
}

const ChallengeMaterials: FC<OwnProps> = ({
  config,
  updateUserPoints,
  updateChallenge,
}): JSX.Element => {
  if (!config || !config?.materials?.length) return <React.Fragment />;

  const [activeItemIndex, setActiveItemIndex] = useState(1);
  const { locale, formatNumber, formatMessage } = useIntl();

  const {
    materials,
    description,
    points,
    title,
    isCampaignActivator,
    isCompleted,
    icon,
  } = config;

  const renderMaterialsCard = ({ cardSpan }: { cardSpan: number }) => {
    const renderMaterialImage = (cardDetails: IMaterial): JSX.Element => {
      const { content, image, type, name } = cardDetails;
      let defaultImage: string | JSX.Element = DEFAULT_IMAGE_BG;
      let iconComponent: JSX.Element;
      let imageComponent: JSX.Element;

      switch (type) {
        case MaterialTypeEnum.FILE:
          defaultImage = DEFAULT_FILE_BG;
          break;
        case MaterialTypeEnum.IMAGE:
          defaultImage = content;
          break;
        case MaterialTypeEnum.VIDEO:
          iconComponent = (
            <a className={`icon icon--player icon__white icon__size-120`} />
          );
          imageComponent = image ? null : (
            <video className="material-card--image" preload="metadata">
              <source src={`${content}#t=0.1`} type="video/mp4" />
            </video>
          );
          break;
      }

      if (!imageComponent)
        imageComponent = (
          <img
            className="material-card--image"
            loading="lazy"
            alt={`material-card--image: ${name}`}
            src={image ? image : defaultImage}
          />
        );

      return (
        <Row
          className="material-card--container-image"
          justify="center"
          align="middle"
        >
          {imageComponent}
          {iconComponent}
        </Row>
      );
    };

    return materials.map((cardDetails: IMaterial, index) => {
      const { description, downloaded, href, idResource, name, points } =
        cardDetails;

      return (
        <Col
          key={`material-card--${idResource}-${index}`}
          md={cardSpan}
          sm={24}
          className="material-card"
        >
          <ChallengeResourceWrapper_Blocked />

          <Row className="material-card--container">
            <Col span={24}>
              {renderMaterialImage(cardDetails)}

              <div className="material-card--body">
                {name && (
                  <Row
                    justify="start"
                    className="block--small material-card--title"
                  >
                    {getTranslatedData(cardDetails, 'name', locale, 'content')}
                  </Row>
                )}
                {!points && description && (
                  <Row className="block--small material-card--description">
                    {getTranslatedData(
                      cardDetails,
                      'description',
                      locale,
                      'content'
                    )}
                  </Row>
                )}
                {points >= configApp.APP.SHOW_POINTS_LIMIT ? (
                  <Row
                    justify="start"
                    align="middle"
                    className="material-card--points"
                  >
                    <div className={`icon icon--puntos`} />
                    <FormattedMessage
                      id="page.challenge.plus-sign-{points}-points"
                      values={{ points: formatNumber(points) }}
                    />
                  </Row>
                ) : null}
              </div>
            </Col>

            {href && (
              <Row
                justify="start"
                align="middle"
                className="material-card--download"
                onClick={() => {
                  if (downloaded) return;

                  completeChallengeUserResource({
                    idResource,
                    updateChallenge,
                    updateUserPoints,
                  });
                }}
              >
                <a className="link" href={href} download>
                  <FormattedMessage id="button.download" />
                </a>
                <a
                  href={href}
                  download
                  className="icon card__icon icon--download"
                />
              </Row>
            )}
          </Row>
        </Col>
      );
    });
  };
  const [carouselGutter, setCarouselGutter] = React.useState(-100);
  const [carouselCards, setCarouselCards] = React.useState(1);

  useEffect(() => {
    const handleResize = () => {
      let _gutter: number;
      let _cards = 1;

      switch (true) {
        case window.innerWidth >= 1000:
          _gutter = -140;
          _cards = 3;
          break;
        case window.innerWidth >= 930:
          _gutter = -90;
          _cards = 2;
          break;
        case window.innerWidth >= 760:
          _gutter = -180;
          _cards = 2;
          break;
        case window.innerWidth >= 570:
          _gutter = -40;
          _cards = 2;
          break;
        case window.innerWidth >= 470:
          _gutter = -200;
          _cards = 1;
          break;
        case window.innerWidth >= 430:
          _gutter = -120;
          break;
        case window.innerWidth >= 400:
          _gutter = -95;
          break;
        case window.innerWidth >= 370:
          _gutter = -85;
          break;
        case window.innerWidth >= 350:
          _gutter = -70;
          break;
        case window.innerWidth >= 320:
          _gutter = -40;
          break;
      }

      setCarouselGutter(_gutter);
      setCarouselCards(_cards);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const hideCarousel =
    materials.length <= MIN_NUMBER_OF_CAROUSEL_CARD || isCampaignActivator;

  const component = (
    <>
      {/* DESKTOP MATERIAL*/}
      <Row
        className={`${hideCarousel ? '' : 'show_only_desktop'}`}
        justify="center"
        gutter={hideCarousel ? [0, 0] : [24, 48]}
      >
        {renderMaterialsCard({ cardSpan: 6 })}
      </Row>

      {/* CAROUSEL*/}
      <div
        className={`material-card__carousel ${
          hideCarousel ? 'no-display' : ''
        }`}
      >
        <ItemsCarousel
          requestToChangeActive={setActiveItemIndex}
          activeItemIndex={activeItemIndex}
          numberOfCards={carouselCards}
          gutter={carouselGutter}
        >
          {renderMaterialsCard({ cardSpan: 24 })}
        </ItemsCarousel>
      </div>
    </>
  );

  const label = isCampaignActivator ? '--trigger-module' : '';

  return (
    <ChallengeResourceWrapper
      styleClass="challengeDetail--Material"
      description={description}
      points={
        points > 0 &&
        formatMessage(
          { id: 'page.challenge.plus-sign-{points}-points' },
          { points: formatNumber(points) }
        )
      }
      icon={icon}
      title={title}
      children={component}
      bottomLabel={
        isCompleted && {
          label: `page.challenge.congratulations-materials-download${label}`,
          style: 'completed',
        }
      }
    />
  );
};

export default ChallengeMaterials;
