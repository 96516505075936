import { Col, Row } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { fetchProductsLimit, updateBagProduct } from 'src/actions/appActions';
import { loadingSpinner } from 'src/components';
import { IRootReducers } from 'src/reducers';
import { IBagProduct } from 'src/shared/models';
import { ProductQuantityButtons } from '../../../utils';
import {
  YuFlex,
  YuIcon,
  YuParagraph,
  YuPopperBase,
  YuQuantitySelector,
  YuSpacer,
  YuTitle,
  YuTooltip,
} from '@isdin/yuma-react-web-pin';
import { BannerReferenceLimitedInfo } from '../orderLimits/bannerReferenceLimitedInfo';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IBagProduct;

const MyBagProduct: FC<Props> = ({
  canUpdateProduct,
  cart,
  maxSaleRestrictions,
  handleRemoveProduct,
  isCheckoutProduct = false,
  isCompact = false,
  product,
  updateBagProduct,
  fetchProductsLimit,
  orderRestrictions,
}) => {
  const { maxSpecificProductByPeriod } = maxSaleRestrictions.annual;
  const { formatMessage } = useIntl();
  const { qty, name, price } = product;
  const [productLoading, setProductLoading] = useState(false);
  const { formatNumber } = useIntl();

  const [maxQuantity, setMaxQuantity] = useState<number>(
    Number(maxSpecificProductByPeriod) ?? 999
  );

  const annualMaxQuantityProduct = Number(maxSpecificProductByPeriod) ?? 999;
  const maxProductsOrdered = annualMaxQuantityProduct === qty;

  useEffect(() => {
    fetchProductsLimit();
  }, []);

  const handleChangeQuantity = async (qty: number) => {
    if (!productLoading) {
      setProductLoading(true);
      const _product = { ...product, qty };
      const updeteBagProductResponse = await updateBagProduct(_product, cart);

      if (!updeteBagProductResponse) {
        setMaxQuantity(qty - 1);
      }
      if (qty <= 0) {
        return await handleRemoveProduct(product, setProductLoading);
      }

      cart.items.map((bagProduct) => {
        if (bagProduct.sku === product.sku) {
          return _product;
        }

        return bagProduct;
      });

      setProductLoading(false);
    }
  };

  const oneRowMode = (
    <>
      <Row
        align="middle"
        className={`my-bag__product ${
          isCompact ? 'no-display' : 'not_show_on_tablet'
        }`}
      >
        <Col sm={5} lg={3}>
          <img className="product__image" src={product.image} loading="lazy" />
        </Col>
        <Col sm={6} lg={10}>
          <YuTitle size="XS">{name}</YuTitle>
        </Col>
        {canUpdateProduct && (
          <Col sm={5} lg={5}>
            <YuFlex gap="XS" align="center">
              <ProductQuantityButtons
                {...{
                  qty,
                  handleChangeQuantity,
                  maxQuantity,
                }}
              />
              {annualMaxQuantityProduct <= qty && (
                <YuPopperBase
                  colorVariant="dark"
                  content={
                    <div style={{ maxWidth: 190 }}>
                      {formatMessage(
                        { id: 'page.my-bag.annual-reference-limit' },
                        {
                          limit:
                            orderRestrictions?.salesRestrictions
                              ?.maxSaleRestrictions?.annual
                              ?.maxSpecificProductByPeriod,
                          units: formatMessage({
                            id:
                              Number(
                                orderRestrictions?.salesRestrictions
                                  ?.maxSaleRestrictions?.annual
                                  ?.maxSpecificProductByPeriod
                              ) > 1
                                ? 'page.redeem-points.errors.units'
                                : 'page.redeem-points.errors.unit',
                          }),
                        }
                      )}
                    </div>
                  }
                >
                  <YuIcon name="Info" size="M" />
                </YuPopperBase>
              )}
            </YuFlex>
          </Col>
        )}
        <Col sm={6} lg={5}>
          <YuParagraph size="L" fw="bold" style={{ textAlign: 'right' }}>
            <FormattedMessage
              id="menu.section.personal.{points}-point"
              values={{ points: formatNumber(price * qty) }}
            />
          </YuParagraph>
        </Col>
        {handleRemoveProduct && (
          <Col sm={2} lg={1}>
            <YuIcon
              size="L"
              name="Trash"
              onClick={() => handleRemoveProduct(product, setProductLoading)}
            />
          </Col>
        )}
        {maxQuantity <= qty && !maxProductsOrdered ? (
          <Col sm={24} lg={24}>
            <YuSpacer size="XS" />
            <BannerReferenceLimitedInfo remaining={maxQuantity} />
          </Col>
        ) : null}
      </Row>
    </>
  );

  const compactMode = (
    <Row className={`my-bag__product ${isCompact ? '' : 'show_on_tablet'}`}>
      <Col xs={11} sm={6}>
        <img className="product__image" src={product.image} loading="lazy" />
      </Col>
      <Col xs={13} sm={18} className="product__details">
        <Row justify="space-between" align="middle">
          <Row justify="space-between" align="top">
            <div
              style={handleRemoveProduct ? { width: 'calc(100% - 28px)' } : {}}
            >
              <YuTitle size="XS">{name}</YuTitle>
            </div>
            {handleRemoveProduct && (
              <div style={{ width: '20px' }}>
                <YuIcon
                  size="M"
                  name="Trash"
                  onClick={() =>
                    handleRemoveProduct(product, setProductLoading)
                  }
                ></YuIcon>
              </div>
            )}
          </Row>
          <p
            className={` ${
              isCheckoutProduct ? 'no-display' : 'show_on_tablet'
            }`}
          >
            <YuSpacer size="XXS" />
            <YuParagraph size="L" fw="bold">
              <FormattedMessage
                id="menu.section.personal.{points}-point"
                values={{ points: formatNumber(price * qty) }}
              />
            </YuParagraph>
          </p>
        </Row>
        {!canUpdateProduct && (
          <Row
            align="middle"
            className={
              isCheckoutProduct
                ? 'product__checkout-style'
                : 'product__lateral-bag-quantity '
            }
          >
            <YuParagraph size="L" fw="bold">
              <FormattedMessage
                id="page.redeem-points.checkout.x-quantity"
                values={{ quantity: qty }}
              />
            </YuParagraph>
          </Row>
        )}
        <Row
          align="middle"
          className={
            isCheckoutProduct
              ? 'product__checkout-style'
              : 'product__lateral-bag'
          }
        >
          {canUpdateProduct && !isCheckoutProduct && (
            <Col
              xs={isCheckoutProduct ? 0 : 24}
              sm={14}
              className={isCheckoutProduct ? 'not_show_on_tablet' : ''}
            >
              <Row>
                <YuQuantitySelector
                  disabled={false}
                  minValue={0}
                  onSetQuantity={handleChangeQuantity}
                  size="XXS"
                  value={qty}
                  variant="nonEditable"
                  maxValue={maxQuantity}
                />
                {annualMaxQuantityProduct <= qty && (
                  <div className="tooltip-my-bag">
                    <YuTooltip
                      colorVariant="dark"
                      text={formatMessage(
                        {
                          id: 'page.redeem-points.my-bag.limit-message-{maxProducts}',
                        },
                        {
                          maxProducts: annualMaxQuantityProduct,
                          units: formatMessage({
                            id:
                              annualMaxQuantityProduct > 1
                                ? 'page.redeem-points.errors.units'
                                : 'page.redeem-points.errors.unit',
                          }),
                        }
                      )}
                    >
                      <YuIcon name="Info" size="M" />
                    </YuTooltip>
                  </div>
                )}
              </Row>
            </Col>
          )}
          {isCheckoutProduct && (
            <>
              <Col
                xs={isCheckoutProduct ? 0 : 18}
                sm={12}
                className={isCheckoutProduct ? ' ' : ''}
              >
                <p className="product__quantity">
                  <FormattedMessage
                    id="page.redeem-points.checkout.x-quantity"
                    values={{ quantity: qty }}
                  />
                </p>
              </Col>
              <Col
                xs={isCheckoutProduct ? 12 : 0}
                className={isCheckoutProduct ? 'no-display' : ''}
              >
                <p className="product__quantity">
                  <FormattedMessage
                    id="page.redeem-points.checkout.x-quantity"
                    values={{ quantity: qty }}
                  />
                </p>
              </Col>
            </>
          )}
          <Col
            xs={isCheckoutProduct ? 12 : 0}
            sm={10}
            className={isCheckoutProduct ? '' : 'not_show_on_tablet'}
          >
            <p>
              <YuParagraph size="L" fw="bold">
                <FormattedMessage
                  id="menu.section.personal.{points}-point"
                  values={{ points: formatNumber(price * qty) }}
                />
              </YuParagraph>
            </p>
          </Col>
        </Row>
      </Col>
      {maxQuantity <= qty && !maxProductsOrdered && (
        <>
          <YuSpacer size="S" />
          <BannerReferenceLimitedInfo remaining={maxQuantity} />
        </>
      )}
    </Row>
  );

  return (
    <div className={productLoading ? 'my-bag__product--loading' : ''}>
      {productLoading && loadingSpinner}
      {productLoading && <div className="product__overlay"></div>}
      {isCompact ? null : oneRowMode}
      {compactMode}
    </div>
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    orderRestrictions: state.auth.user.magento?.orderRestrictions,
    cart: state.app.redeemPoints.cart,
    bagProductsList: state.app.redeemPoints.cart.items,
    productsLimit: state.app.productsLimit,
    maxSaleRestrictions:
      state.auth.user.magento?.orderRestrictions.salesRestrictions
        .maxSaleRestrictions,
    redeemedProductsByTime:
      state.auth.user.magento?.orderRestrictions.redeemedProductsByTime,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      updateBagProduct,
      fetchProductsLimit,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MyBagProduct);
