import { config } from 'config/config';
import { UserStatusEnum } from 'src/shared/enums/user.enum';
import { IMenu } from 'src/shared/models';
import { redeemPointStatus } from '../../../utils';

export enum MenuEnum {
  PUBLIC_MENU_POINT = 1,
  PRIVATE_MENU_POINT = 2,
  PUBLIC_FOOTER_PARENT_POINT = 3,
  PUBLIC_FOOTER_CHILD_POINT = 4,
  PRIVATE_FOOTER_PARENT_POINT = 5,
  PRIVATE_FOOTER_CHILD_POINT = 6,
}

const {
  PUBLIC_FOOTER_PARENT_POINT,
  PUBLIC_FOOTER_CHILD_POINT,
  PUBLIC_MENU_POINT,
  PRIVATE_MENU_POINT,
} = MenuEnum;

const constructMenu = (menu: IMenu[], userStatus: UserStatusEnum) => {
  const checkedMenu = menu
    .sort((a: IMenu, b: IMenu) => a.order - b.order)
    .filter(
      (_menu) =>
        _menu.status &&
        (_menu.type === PUBLIC_MENU_POINT || _menu.type === PRIVATE_MENU_POINT)
    );

  checkedMenu.forEach((item) => {
    if (
      item.absolute &&
      item.route.includes(config.MAGENTO.BASE_URL) &&
      !redeemPointStatus.includes(userStatus)
    )
      item.disabled = true;
  });

  return checkedMenu;
};

export const getMenu = (
  authenticated: boolean,
  _publicMenu: IMenu[] = [],
  _privateMenu: IMenu[] = [],
  userStatus: UserStatusEnum
) => {
  const publicMenu = _publicMenu ?? [];
  const privateMenu = _privateMenu ?? [];

  if (authenticated) return constructMenu(privateMenu, userStatus);

  return (
    publicMenu
      .sort((a: IMenu, b: IMenu) => a.order - b.order)
      .filter(({ status, type }) => status && type === PUBLIC_MENU_POINT) ?? []
  );
};

export const getFooter = (
  authenticated: boolean,
  _publicMenu: IMenu[],
  _privateMenu: IMenu[],
  isBottomFooter?: boolean
): IMenu[] => {
  const publicMenu = _publicMenu ?? [];
  const privateMenu = _privateMenu ?? [];

  const bottomFooterNames = ['PRIVATE BOTTOM FOOTER', 'PUBLIC BOTTOM FOOTER'];
  const publicParentMenus = publicMenu?.filter(
    (menuItem) => menuItem.type === MenuEnum.PUBLIC_FOOTER_PARENT_POINT
  );
  const privateParentMenus = privateMenu?.filter(
    (menuItem) => menuItem.type === MenuEnum.PRIVATE_FOOTER_PARENT_POINT
  );
  const filterMenu = ({ status, visible }: IMenu) => status && visible;
  const menu = authenticated ? privateParentMenus : publicParentMenus;
  const menuVisible = menu.filter(filterMenu).map((menuItem) => {
    return {
      ...menuItem,
      subMenu: menuItem.subMenu
        .filter(filterMenu)
        .sort((a, b) => a.order - b.order),
    };
  });

  if (menu !== null) {
    return isBottomFooter
      ? menuVisible?.filter((item) => bottomFooterNames.includes(item.name))
      : menuVisible?.filter((item) => !bottomFooterNames.includes(item.name));
  }
};

export const getPublicMenus = (menuArr: IMenu[]) => {
  if (!menuArr || !menuArr.length) return [];
  const availableMenus = [
    PUBLIC_MENU_POINT,
    PUBLIC_FOOTER_PARENT_POINT,
    PUBLIC_FOOTER_CHILD_POINT,
  ];

  const entries = [];

  menuArr.forEach((menu) => {
    const { type } = menu;
    if (Object.values(availableMenus).includes(type)) entries.push(menu.route);
  });
  return entries;
};
