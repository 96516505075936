import { notification } from 'antd';
import { config } from 'config/config';
import * as api from 'services/api';
import apiPaths from 'services/apiPaths';
import {
  CampaignModuleStatusEnum,
  ResourceTypeDetailEnum,
  ResourceTypeEnum,
} from 'src/shared/enums';
import { ICustomer, ICustomerAddress, IRegion } from 'src/shared/models';
import { IResourceList } from '../challenge/ChallengeInterfaces';
import { CampaignLabelStatus } from '../challenge/ChallengeResourceWrapper';
import { isPlatformPIN, isUSAPlatform } from '../../../utils';

const { BASE_URL } = config.APP;

const {
  SHOPPING_CART_DESCRIPTION,
  SHOPPING_CART_CONDITIONS,
  SHOPPING_CART_BUTTON_TEXT,
  SHOPPING_CART_MAX_ITEMS,
  SHOPPING_CART_MIN_ITEMS,
  SHOPPING_CART_GROUP_DESCRIPTION,
  SHOPPING_CART_GROUP_MAX_ITEMS,
  SHOPPING_CART_GROUP_MIN_ITEMS,
  SHOPPING_CART_PRODUCT_ID,
  SHOPPING_CART_PRODUCT_MAX_ITEMS,
  SHOPPING_CART_PRODUCT_MIN_ITEMS,
  SHOPPING_CART_PRODUCT_DEFAULT_ITEMS,
  SHOPPING_CART_SHOW_PRICES,
} = ResourceTypeDetailEnum;

export interface IShoppingCart {
  buttonText: string;
  conditionsContent: string;
  description: string;
  groups: IShoppingGroup[];
  groupsQuantity?: number;
  isCorrect: boolean;
  isStockCorrect: boolean;
  maxItems?: number;
  minItems?: number;
  name: string;
  points: number;
  showPrices?: boolean;
}

export interface IShoppingGroup {
  isCorrect: boolean;
  maxItems?: number;
  minItems?: number;
  name: string;
  products: IShoppingProduct[];
  productsQuantity?: number;
}

export interface IShoppingProduct {
  description: string;
  id: number;
  idProduct: string;
  idResource: number;
  image?: string;
  isCorrect?: boolean;
  maxItems: number;
  minItems: number;
  name: string;
  quantity: number;
  sku: string;
  stock: number;
  price?: number;
  pvpr: number;
}

export interface IMagentoProduct {
  description: string;
  id: number;
  image: string;
  name: string;
  sku: string;
  stock: number;
}

export interface IOrderProduct {
  productId: string;
  qty: number;
}

export const buildCartInfo = (
  { name, resourceDetailList = [], resourceList = [], score }: IResourceList,
  shoppingProducts: IMagentoProduct[]
) => {
  const cartInfo = { name, isStockCorrect: true } as IShoppingCart;
  let isCorrect = true;

  resourceDetailList
    .filter(({ status }) => status)
    .forEach(({ idResourceTypeD: { idResourceTypeD }, value }) => {
      switch (idResourceTypeD) {
        case SHOPPING_CART_DESCRIPTION:
          cartInfo.description = value;
          break;
        case SHOPPING_CART_CONDITIONS:
          cartInfo.conditionsContent = value;
          break;
        case SHOPPING_CART_BUTTON_TEXT:
          cartInfo.buttonText = value;
          break;
        case SHOPPING_CART_MAX_ITEMS:
          cartInfo.maxItems = +value;
          break;
        case SHOPPING_CART_MIN_ITEMS:
          cartInfo.minItems = +value;
          break;
        case SHOPPING_CART_SHOW_PRICES:
          cartInfo.showPrices = value === 'true';
          break;
        default:
          break;
      }
    });

  cartInfo.groupsQuantity = 0;
  cartInfo.groups = resourceList
    .filter(
      ({ status, idResourceType: { idResourceType } }) =>
        status && idResourceType === ResourceTypeEnum.SHOPPING_CART_GROUP
    )
    .map((resource: IResourceList) => {
      const [group, groupOutOfStock] = buildGroupInfo(
        resource,
        shoppingProducts
      );
      if (groupOutOfStock) cartInfo.isStockCorrect = false;
      cartInfo.groupsQuantity += group.productsQuantity;
      return group;
    });

  const productsQuantity =
    cartInfo.groups
      .map((group) => {
        if (!group.isCorrect) isCorrect = false;
        return group.productsQuantity;
      })
      .reduce((curr, acc) => curr + acc) || 0;

  if (
    isCorrect &&
    !(
      cartInfo.minItems <= productsQuantity &&
      productsQuantity <= cartInfo.maxItems
    )
  )
    isCorrect = false;

  cartInfo.points = score?.points || 0;

  return { ...cartInfo, isCorrect };
};

export const buildGroupInfo = (
  { name, resourceDetailList = [], resourceList = [] }: IResourceList,
  shoppingProducts: IMagentoProduct[]
): [IShoppingGroup, boolean] => {
  const groupInfo = { name } as IShoppingGroup;
  let isCorrect = true;
  let outOfStock = false;

  resourceDetailList
    .filter(({ status }) => status)
    .forEach(({ idResourceTypeD: { idResourceTypeD }, value }) => {
      switch (idResourceTypeD) {
        case SHOPPING_CART_GROUP_DESCRIPTION:
          groupInfo.name = value;
          break;
        case SHOPPING_CART_GROUP_MAX_ITEMS:
          groupInfo.maxItems = +value;
          break;
        case SHOPPING_CART_GROUP_MIN_ITEMS:
          groupInfo.minItems = +value;
          break;
        default:
          break;
      }
    });

  const products =
    resourceList.map((resource: IResourceList) => {
      const [product, productOutOfStock] = buildProductInfo(
        resource,
        shoppingProducts
      );
      if (productOutOfStock) outOfStock = true;
      return product;
    }) || [];

  const productsQuantity =
    products
      .map((product) => {
        if (!product.isCorrect) isCorrect = false;
        return product.quantity;
      })
      .reduce((acc, curr) => acc + curr, 0) || 0;

  groupInfo.productsQuantity = productsQuantity;
  if (
    isCorrect &&
    !(
      groupInfo.minItems <= productsQuantity &&
      productsQuantity <= groupInfo.maxItems
    )
  )
    isCorrect = false;

  return [{ ...groupInfo, products, isCorrect }, outOfStock];
};

export const buildProductInfo = (
  { idResource, resourceDetailList }: IResourceList,
  shoppingProducts: IMagentoProduct[]
): [IShoppingProduct, boolean] => {
  const productInfo = { idResource } as IShoppingProduct;
  const isCorrect = true;
  let outOfStock = false;

  resourceDetailList
    .filter(({ status }) => status)
    .forEach(({ idResourceTypeD: { idResourceTypeD }, value }) => {
      switch (idResourceTypeD) {
        case SHOPPING_CART_PRODUCT_ID:
          productInfo.idProduct = value;
          break;
        case SHOPPING_CART_PRODUCT_MAX_ITEMS:
          productInfo.maxItems = +value;
          break;
        case SHOPPING_CART_PRODUCT_MIN_ITEMS:
          productInfo.minItems = +value;
          break;
        case SHOPPING_CART_PRODUCT_DEFAULT_ITEMS:
          productInfo.quantity = +value;
          break;
        default:
          break;
      }
    });

  // TODO comprobar el stock
  const magentoExtraData = shoppingProducts?.find(
    ({ sku }) => productInfo.idProduct === sku
  );

  if (magentoExtraData) {
    if (magentoExtraData.stock < productInfo.minItems) outOfStock = true;
    if (magentoExtraData.stock < productInfo.maxItems)
      productInfo.maxItems = magentoExtraData.stock;
    if (magentoExtraData.stock < productInfo.quantity)
      productInfo.quantity = magentoExtraData.stock;
  }

  productInfo.quantity = Math.max(productInfo.quantity, productInfo.minItems);

  return [{ ...productInfo, ...magentoExtraData, isCorrect }, outOfStock];
};

export const modifyProductQuantity = (
  cartInfo: IShoppingCart,
  idResource: number,
  quantity = 0
) => {
  const newCart = { ...cartInfo };

  // set new quantity

  cartInfo.groups.forEach((group) => {
    group.products.forEach((product) => {
      const { idResource: id, maxItems, minItems } = product;
      if (id === idResource && quantity <= maxItems && quantity >= minItems) {
        product.quantity = quantity;
      }
    });
  });

  return validatedShoppingCart(newCart);
};

export const validatedShoppingCart = (
  shoppingInfo: IShoppingCart
): IShoppingCart => {
  let isCorrect = true;

  const totalProducts =
    shoppingInfo.groups
      .map((group) => {
        const groupProducts =
          group.products
            .map((product) => {
              // check product items
              if (!isValidProduct(product)) isCorrect = false;
              return product.quantity;
            })
            .reduce((acc, curr) => acc + curr) || 0;

        // check group items
        group.productsQuantity = groupProducts;
        if (!isValidGroup(group)) isCorrect = false;

        return groupProducts;
      })
      .reduce((acc, curr) => acc + curr) || 0;
  // check total items

  shoppingInfo.groupsQuantity = totalProducts;
  if (!isValidCart(shoppingInfo)) isCorrect = false;

  return { ...shoppingInfo, isCorrect };
};

export const getShoppingCartLabel = ({
  isCompleted,
  isActivator,
  isStockCorrect,
}: {
  isCompleted: boolean;
  isActivator: boolean;
  isStockCorrect: boolean;
  shoppingCartStatus?: CampaignModuleStatusEnum;
}): null | { label: string; style: CampaignLabelStatus } => {
  switch (true) {
    case isCompleted && isActivator:
      return {
        label: 'page.challenge.congratulations-shopping-completed-activator',
        style: 'completed',
      };
    case isCompleted:
      return {
        label: 'page.challenge.congratulations-shopping-completed',
        style: 'completed',
      };
    case !isStockCorrect:
      return {
        label: 'page.challenge.shopping-no-stock',
        style: 'error',
      };
    case !isCompleted && isActivator:
      return {
        label: 'page.challenge.complete-shopping-to-activate',
        style: 'plain',
      };
  }
  return null;
};

export const getShoppingCartStatusLabel = (status) => {
  switch (status) {
    case CampaignModuleStatusEnum.PROCESSED:
      return {
        label: 'page.challenge.complete-shopping-status-correct',
        labelLink: `${BASE_URL}/account/orders`,
        style: 'correct',
        icon: 'process-check',
      };
    case CampaignModuleStatusEnum.SHOPPING_CART_ERROR:
      return {
        label: 'page.challenge.complete-shopping-status-error',
        labelLink: `${BASE_URL}/contact-rep`,
        style: 'error',
        icon: 'process-cross',
      };
    case CampaignModuleStatusEnum.SHOPPING_CART_IN_PROGRESS:
      return {
        label: 'page.challenge.complete-shopping-status-in-progress',
        style: 'in-progress',
      };
  }
};

export const getOrderProducts = (cart: IShoppingCart): IOrderProduct[] => {
  const orderProducts: IOrderProduct[] = [];
  if (!cart.groups?.length) return;

  cart.groups.forEach(({ products }: IShoppingGroup) => {
    if (!products?.length) return;

    products?.forEach(({ sku, quantity }: IShoppingProduct) => {
      if (quantity === 0 || !sku) return;
      orderProducts.push({ qty: quantity, productId: sku });
    });
  });
  return orderProducts;
};

const isValidProduct = ({ quantity, maxItems, minItems }: IShoppingProduct) => {
  return checkQuantity({ quantity, minItems, maxItems });
};

const isValidGroup = ({
  productsQuantity,
  maxItems,
  minItems,
}: IShoppingGroup) => {
  return checkQuantity({ quantity: productsQuantity, minItems, maxItems });
};

const isValidCart = ({ groupsQuantity, maxItems, minItems }: IShoppingCart) => {
  return checkQuantity({ quantity: groupsQuantity, minItems, maxItems });
};

const checkQuantity = ({
  quantity,
  maxItems,
  minItems,
}: {
  quantity: number | null;
  maxItems: number | null;
  minItems: number | null;
}) => {
  if (quantity === null) return true;
  if (maxItems !== null && quantity > maxItems) return false;
  if (minItems !== null && quantity < minItems) return false;
  return true;
};

export const createOrEditAddress = async (
  formValues,
  isEdit,
  {
    customer,
    userCountry,
    stateList,
    updateMagentoData,
    formatMessage,
    addressToEdit = null,
  }
) => {
  const PREFIX = 'Mr';
  const { city, zipcode: postcode, addressState } = formValues;
  const { firstname, lastname, middlename, telephone } = customer;

  // TODO: modify the api to be able to use different platforms' provinces/states
  if (!isUSAPlatform && config.APP.PROVINCES[config.APP.PLATFORM])
    stateList = {
      available_regions: config.APP.PROVINCES[config.APP.PLATFORM].filter(
        ({ code }) => code !== null
      ),
    };

  const region: IRegion = stateList.available_regions.find(
    (region: IRegion) => region.id === addressState
  );

  const newAddress: ICustomerAddress = {
    id: isEdit ? addressToEdit.id : null,
    customer_id: customer.id,
    country_id: userCountry,
    region: {
      region_code: region.code.toString(),
      region: region.name,
      region_id: region.id,
    },
    street: [formValues['addressStreet'], formValues['addressNumber']],
    telephone,
    postcode,
    city,
    firstname,
    lastname,
    middlename,
    prefix: PREFIX,
    default_shipping: false,
    default_billing: false,
  };

  let data: { customer?: ICustomer; address: ICustomerAddress };

  if (isPlatformPIN) {
    data = {
      address: newAddress,
    };
  } else {
    data = {
      customer,
      address: newAddress,
    };
  }

  try {
    let response;

    if (isEdit) {
      response = await api.putDataCall({
        dataPath: apiPaths.MAGENTO.ISDIN_ADDRESS,
        data,
        callConfig: {},
      });
    } else {
      response = await api.postDataCall({
        dataPath: apiPaths.MAGENTO.ISDIN_ADDRESS,
        data,
        callConfig: {},
      });
    }

    const address = isEdit
      ? response.data.addresses.find(
          (address: ICustomerAddress) => address.id === addressToEdit.id
        )
      : response.data.addresses[response.data.addresses.length - 1];

    notification.success({
      message: isEdit
        ? formatMessage({ id: 'form.address.update-success' })
        : formatMessage({ id: 'form.address.create-success' }),
    });

    updateMagentoData(response.data);

    return [address, response];
  } catch (err) {
    console.group(err);
    notification.error({
      message: formatMessage({ id: 'form.address.no-create' }),
    });
  }
};
