import { Col, Dropdown, Menu, notification, Row } from 'antd';
import routePaths from 'config/routes';
import { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { logout } from 'src/actions/authActions';
import { showBagMenu } from 'src/actions/appActions';
import Link from 'src/components/Link';
import { IRootReducers } from 'src/reducers';
import { shouldGoToDelegatePage } from 'src/shared/enums';
import { CheckOutCart, redeemPointStatus } from '../../utils';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const SignInHeader: NextPage<Props, unknown> = ({
  accessToken,
  cartItems,
  logout,
  user,
  userStatus,
  userPoints,
  showBagMenu,
}): JSX.Element => {
  const { formatMessage, formatNumber } = useIntl();
  const [nItemsCart, setnItemsCart] = useState(0);
  const userMainData = user?.userMainData;
  const path = useRouter();

  const onLogout = async () => {
    try {
      await logout();

      notification['success']({
        message: formatMessage({ id: 'app.logout' }),
      });
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        notification['error']({
          message: formatMessage({ id: 'app.logout-error' }),
          description: `${data.code} ${data.error}`,
        });
      }
      console.error(error);
    }
  };

  const dropdownMenu = (
    <Menu>
      <Menu.Item>
        <Link href={routePaths.PAGES.MY_ACCOUNT.MY_PROFILE}>
          <a>{formatMessage({ id: 'menu.header.my-account' })}</a>
        </Link>
      </Menu.Item>
      <Menu.Item onClick={onLogout}>
        <a>{formatMessage({ id: 'menu.header.logout' })}</a>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    let qty = 0;
    cartItems.map((item) => {
      qty += item.qty;
    });
    setnItemsCart(qty);
  }, [cartItems]);

  const getContactRoute = (): string => {
    const haveDelegate = user?.salesforce?.centerDelegates?.length;
    const { CONTACT_REP, LOGIN } = routePaths.PAGES;
    const { CONTACT_US } = routePaths.FOOTER;

    if (!accessToken) return LOGIN;

    return haveDelegate && shouldGoToDelegatePage() ? CONTACT_REP : CONTACT_US;
  };

  return (
    <div className={'basicHeader__signin'}>
      <Row justify="end" className="container basicHeader__signin__container">
        <Col className="basicHeader__signin__col">
          <Link href={getContactRoute()}>
            <a className="link">
              {formatMessage({ id: 'menu.header.contact' })}
            </a>
          </Link>
        </Col>

        <Col className="basicHeader__signin__wrapper basicHeader__signin__col">
          {user ? (
            <Dropdown
              overlay={dropdownMenu}
              className="basicHeader__signin__dropdown"
            >
              <div className="link">
                <Link
                  href={
                    accessToken
                      ? routePaths.PAGES.MY_ACCOUNT.MY_PROFILE
                      : routePaths.PAGES.LOGIN
                  }
                >
                  <a className="basicHeader__signin__icon basicHeader__signin__icon--user"></a>
                </Link>
                <span>
                  {formatMessage(
                    { id: 'menu.header.hello' },
                    {
                      name: userMainData?.name,
                      surname: userMainData?.lastname,
                    }
                  )}
                  <b style={{ fontWeight: 600 }}>
                    {typeof userPoints === 'number' &&
                      formatMessage(
                        { id: 'menu.header.points' },
                        { points: formatNumber(userPoints) }
                      )}
                  </b>
                </span>
              </div>
            </Dropdown>
          ) : (
            <Link
              href={
                accessToken
                  ? routePaths.PAGES.MY_ACCOUNT.MY_PROFILE
                  : routePaths.PAGES.LOGIN
              }
            >
              <a className="basicHeader__signin__icon basicHeader__signin__icon--user"></a>
            </Link>
          )}
        </Col>
        <Col className="basicHeader__signin__col">
          <div className="link basicHeader__signin__wrapper">
            <CheckOutCart
              showBagMenu={showBagMenu}
              cartItems={nItemsCart}
              user={user}
              path={path}
              enabled={redeemPointStatus.includes(userStatus)}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    user: state.auth.user,
    accessToken: state.auth.accessToken,
    userPoints: state.auth.userPoints,
    userStatus: state.auth.userStatus,
    cartItems: state.app.redeemPoints.cart.items,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ logout, showBagMenu }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SignInHeader);
