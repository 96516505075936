import { Button, Col, Row } from 'antd';
import React, { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { config as configApp } from 'config/config';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import * as api from 'services/api';
import ChallengeResourceWrapper, {
  ChallengeResourceWrapper_Blocked,
} from 'src/components/challenge/ChallengeResourceWrapper';
import { IRootReducers } from 'src/reducers';
import { IChallengeResourceRender } from 'src/shared/models';
import { IChallengeSurvey } from 'src/shared/models/challengeSurvey.model';
import { scrollDown } from '../../../utils';
import TypeformComponent from '../TypeformComponent';

interface OwnProps extends IChallengeResourceRender {
  config: IChallengeSurvey;
  hideTopPoints: boolean;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

const ChallengeSurvey: FC<Props> = ({
  user,
  config,
  shortDateFormat,
  updateChallenge,
  updateUserPoints,
  hideTopPoints,
}) => {
  if (!config) return <React.Fragment />;

  const { idResource, isCompleted } = config;

  const { formatMessage, formatNumber } = useIntl();
  const [showSurvey, setShowSurvey] = useState(false);

  const handleShowSurvey = () => {
    scrollDown(`survey-form-${config.typeformID}`);
  };

  const outOfDates = !config.isStarted || config.isExpired;

  const renderButton = () => {
    if (isCompleted) return;

    return (
      <Col className="surveyPage-survey-button">
        {outOfDates ? (
          <Row justify="center" align="middle">
            <div
              className={`challengeResourceWrapper__label challengeResourceWrapper__label${
                config.isExpired ? '--error' : '--pending'
              }`}
            >
              <FormattedMessage
                id={
                  config.isExpired
                    ? 'page.challenge.survey.expired'
                    : 'page.challenge.headboard-button.not-enabled'
                }
              />
            </div>
          </Row>
        ) : (
          <Button
            style={{ padding: '4px 24px' }}
            className={'btn-primary btn-primary--black'}
            disabled={outOfDates}
            onClick={() => {
              setShowSurvey(true);
              handleShowSurvey();
            }}
            block
          >
            <span className="surveyPage-survey-button-text">
              {config.buttonText ? (
                config.buttonText
              ) : (
                <FormattedMessage id={'page.survey-button.text'} />
              )}
            </span>
          </Button>
        )}
      </Col>
    );
  };

  const renderChallengeDates = (): JSX.Element => {
    return (
      <Row justify="center">
        <i className="challengeCard__icon challengeCard__icon--calendar_detalle" />
        {config.startDate.toFormat(`${shortDateFormat}`)}
        {` - `}
        {config.endDate.toFormat(`${shortDateFormat}`)}
      </Row>
    );
  };

  const handleCompleteSurvey = async () => {
    try {
      const response = await api.completeChallengeSurvey({
        data: {
          idResource,
        },
      });

      const { score, challenge } = response.data || {};

      if (score && typeof score === 'number') updateUserPoints(score);
      if (challenge) updateChallenge(challenge);
    } catch (err) {
      console.error(err);
    }
  };

  const component = (
    <Col>
      <ChallengeResourceWrapper_Blocked />

      <Row justify="center">
        <Row className="surveyPage-dates">{renderChallengeDates()}</Row>
        {config.points >= configApp.APP.SHOW_POINTS_LIMIT && (
          <Row className="surveyPage-survey-points surveyPage-quiz-points block--small">
            <div
              className={
                'icon icon--puntos_grande surveyPage-points-button challenge-detail-box-icon-black'
              }
            />
            <FormattedMessage
              id={'page.challenge.win-{points}-points'}
              values={{
                points: config.points,
              }}
            />
          </Row>
        )}

        <Row className="surveyPage-container-button">{renderButton()}</Row>
      </Row>

      <div
        className="surveyPage-iframe"
        id={`survey-form-${config.idResource}`}
      >
        <TypeformComponent
          {...{
            user: user,
            showSurvey,
            surveyData: {
              idSurvey: config.idResource,
              content: config.description,
              image: null,
              title: config.title,
              name: config.title,
              userResponseList: null,
              idTypeform: config.typeformID,
              score: { points: config.points, idScore: null, status: null },
            },
            completeSurvey: handleCompleteSurvey,
          }}
        />
      </div>
    </Col>
  );

  return (
    <ChallengeResourceWrapper
      styleClass="challengeDetail--Survey"
      bottomLabel={
        config.points !== 0 &&
        config.isCompleted && {
          label: config.messageOnFinish,
          style: 'completed',
        }
      }
      children={component}
      description={config.description}
      icon={config.icon}
      points={
        hideTopPoints
          ? undefined
          : config.points !== 0 &&
            formatMessage(
              { id: 'page.challenge.plus-sign-{points}-points' },
              { points: formatNumber(config.points) }
            )
      }
      title={config.title}
    />
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    user: state.auth.user,
    shortDateFormat: state.platform.shortDateFormat,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeSurvey);
