import { Input } from 'antd';
import {
  ChangeEventHandler,
  ComponentProps,
  FC,
  FocusEventHandler,
  memo,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { CustomFormatMessage } from '../../../../utils';

interface Props extends ComponentProps<'input'> {
  id: string;
  isVisible?: boolean;
  rules?: {
    validator: (value: string | ReadonlyArray<string> | number) => boolean;
    message: ReactNode;
  }[];
  optional?: boolean;
  icon?: string;
  label?: ReactNode;
  oneWordInput?: boolean;
  defaultValue?: string;
  colectiveValidate?: boolean;
  syncValidationsTimestamp?: number;
  helperMessages?: ReactNode[];
  callbackChange?: ChangeEventHandler<HTMLInputElement>;
  callbackValidate?: (id: string, errors: ReactNode[]) => void;
  callbackBlur?: FocusEventHandler<HTMLInputElement>;
}

const InputText: FC<Props> = (props) => {
  const {
    id,
    isVisible = true,
    rules = [],
    optional = false,
    helperMessages = [],
    colectiveValidate = false,
    label,
    icon,
    value,
    oneWordInput = false,
    defaultValue,
    disabled,
    syncValidationsTimestamp,
    callbackValidate,
    callbackChange,
    callbackBlur,
    onChange,
    onBlur,
    ...restProps
  } = props;

  const ref = useRef<Input>(null);
  const [isPristine, setIsPristine] = useState<boolean>(true);
  const [errors, setErrors] = useState<ReactNode[]>([]);
  const [isFirstTime, setIsFirstTime] = useState<boolean>(true);
  const [trimValue, setTrimValue] = useState<
    string | ReadonlyArray<string> | number
  >(value);
  const validateFieldAndUpdate = () => {
    const errorsList = getErrorsList();

    setErrors(errorsList);
    if (callbackValidate) callbackValidate(id, errorsList);
  };

  useEffect(() => {
    if (syncValidationsTimestamp > 0) validateFieldAndUpdate();
  }, [syncValidationsTimestamp]);

  const getErrorsList = () => {
    return rules.flatMap((rule) =>
      rule.validator(trimValue ?? ref.current.input.value) ? [] : [rule.message]
    );
  };

  useEffect(() => {
    setTrimValue(oneWordInput ? value?.toString().replace(/\s/g, '') : value);
    setDataFilled();
  }, [value]);

  const setDataFilled = () => {
    if (ref.current) {
      ref.current?.input.setAttribute(
        'data-filled',
        (ref.current?.input.value !== '').toString()
      );
    }
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setTrimValue(
      oneWordInput ? event.target.value.replace(/\s/g, '') : event.target.value
    );
    setIsPristine(false);
    setDataFilled();
    validateFieldAndUpdate();
    if (onChange) return onChange(event);
    if (callbackChange) return callbackChange(event);
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    const trimmedValue = event.target.value.trim();
    setTrimValue(trimmedValue);
    if (onBlur) return onBlur(event);
    if (callbackBlur) return callbackBlur(event);
  };

  useEffect(() => {
    if (!isFirstTime) validateFieldAndUpdate();
    else setIsFirstTime(false);
  }, [value, trimValue]);

  return (
    <div className={isVisible ? 'form-input' : ''}>
      <Input
        {...(restProps as Partial<Input>)}
        className={`${errors.length ? 'error-validate' : ''} ${
          icon ? 'input-icon' : ''
        } `}
        ref={ref}
        id={id}
        type="text"
        value={trimValue}
        data-isPristine={isPristine ? true : undefined}
        data-errors={errors.length ? true : undefined}
        name={id}
        style={isVisible ? null : { display: 'none' }}
        defaultValue={defaultValue}
        disabled={disabled}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      {isVisible && label && (
        <label
          className={`${value ? 'form-input-select--label' : ''} ${
            icon ? 'input-icon-title' : ''
          }`}
          htmlFor={id}
          key={`form-input-${id}--placeholder`}
        >
          {label}
          {optional && (
            <span className="optional-text">
              <CustomFormatMessage id="form.field.optional" />
            </span>
          )}
        </label>
      )}
      {icon && <a className={`icon icon-field icon--${icon}`} />}
      {isVisible && !!errors.length && (
        <ul className="validation-error">
          {errors.map((message, i) => (
            <li
              className={`ant-form-item-explain ${
                colectiveValidate ? 'colective-validate' : ''
              }`}
              key={i}
            >
              {message}
            </li>
          ))}
        </ul>
      )}
      {isVisible && !!helperMessages.length && (
        <ul className="info-messages">
          {helperMessages.map((message, i) => (
            <li className="message" key={i}>
              <a className={`icon icon-message icon--information`} />
              {message}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default memo(InputText);
