import { Menu } from 'antd';
import { ClickParam, MenuMode } from 'antd/lib/menu';
import { isEmpty } from 'lodash';
import { NextPage } from 'next';
import Link from 'src/components/Link';
import { useRouter } from 'next/router';
import React from 'react';
import { IMenu } from 'src/shared/models';
import { getTranslatedData, requestOrderStatus } from '../../../utils';
import routePaths, { isRouteDefined } from 'config/routes';
import { UserStatusEnum } from '../../shared/enums';

interface OwnProps {
  className?: string;
  menu: Array<IMenu>;
  mode: MenuMode;
  onClickItem?: (param?: ClickParam) => void;
  userStatus: UserStatusEnum;
}

export const getItemLink = (route: string) => {
  return isRouteDefined(route) ? route : `/static?page=${route}`;
};

const BasicMenu: NextPage<OwnProps, unknown> = ({
  className = 'menu',
  menu,
  mode,
  onClickItem,
  userStatus,
}): JSX.Element => {
  const router = useRouter();
  const renderSubMenu = (item: IMenu): JSX.Element => {
    return (
      <Menu.SubMenu key={item.idMenu} title={<span>{item.name}</span>}>
        {item.subMenu.map((subMenuItem) => {
          if (subMenuItem.visible) {
            return (
              <Menu.Item
                key={subMenuItem.idMenu}
                className={subMenuItem.disabled ? 'disabled' : ''}
                onClick={() => (onClickItem ? onClickItem() : {})}
              >
                {subMenuItem.absolute ? (
                  <a
                    href={subMenuItem.route}
                    className={subMenuItem.disabled ? 'disabled' : ''}
                  >
                    {getTranslatedData(subMenuItem, 'content') ||
                      subMenuItem.name}
                  </a>
                ) : (
                  <Link href={getItemLink(subMenuItem.route)}>
                    <a className={subMenuItem.disabled ? 'disabled' : ''}>
                      {getTranslatedData(subMenuItem, 'content') ||
                        subMenuItem.name}
                    </a>
                  </Link>
                )}
              </Menu.Item>
            );
          }
        })}
      </Menu.SubMenu>
    );
  };

  const renderMenuItems = (): JSX.Element[] =>
    menu
      .sort((a: IMenu, b: IMenu) => a.order - b.order)
      .map((item: IMenu): JSX.Element => {
        const { subMenu } = item;
        if (item.visible) {
          if (subMenu && !isEmpty(subMenu)) return renderSubMenu(item);

          const disabled =
            [
              routePaths.PAGES.REQUEST_ORDER,
              routePaths.PAGES.REDEEM_POINTS.MAIN,
            ].includes(item.route) && !requestOrderStatus.includes(userStatus)
              ? true
              : item.disabled || false;

          return (
            <Menu.Item
              key={item.route || item.idMenu}
              className={item.disabled ? 'disabled' : ''}
              disabled={disabled}
              onClick={() => (onClickItem ? onClickItem() : {})}
            >
              {item.absolute ? (
                <a
                  href={item.route}
                  className={item.disabled ? 'disabled' : ''}
                  data-testid={
                    'basic-header-' +
                    item.route.slice(item.route.lastIndexOf('/') + 1)
                  }
                >
                  {getTranslatedData(item, 'content') || item.name}
                </a>
              ) : (
                <Link href={getItemLink(item.route)}>
                  <a
                    className={item.disabled ? 'disabled' : ''}
                    data-testid={
                      'basic-header-' +
                      item.route.slice(item.route.lastIndexOf('/') + 1)
                    }
                  >
                    {getTranslatedData(item, 'content') || item.name}
                  </a>
                </Link>
              )}
            </Menu.Item>
          );
        }
      });

  return (
    <Menu
      selectedKeys={[
        router.pathname === routePaths.PAGES.TRAINING_DETAIL
          ? routePaths.PAGES.TRAINING
          : router.pathname === routePaths.PAGES.TALK_DETAIL ||
            router.pathname === routePaths.PAGES.CONGRESS_DETAIL
          ? routePaths.PAGES.TALK
          : router.pathname === routePaths.PAGES.CAMPAIGN_DETAIL
          ? routePaths.PAGES.CAMPAIGN
          : router.pathname,
      ]}
      className={className}
      mode={mode}
    >
      {renderMenuItems()}
    </Menu>
  );
};

export default BasicMenu;
